import Qs from "qs";
import axios from "axios";

// export default request;

// {data: qs.stringify(data),
// url,
// headers,
// method: 'DELETE',
// }

const http = axios.create({
  // baseURL: process.env.VUE_APP_EN_URL || "",
  timeout: 30000000,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    Accept: "application/json;charset=UTF-8"
  }
});

export const request = (config) => new Promise((resolve, reject) => {
      http.request({
          ...config,
          headers: config.headers
        })
        .then((res) => {
          if (!res.code) {
            resolve(res?.data); // 将结果返回出去
          }
          if (res.code === 200) {
            resolve(res?.data); // 将结果返回出去
          }
        })
        .catch((err) => {
          reject(err);

          return err;
        });
    });
