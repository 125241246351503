<!-- eslint-disable no-await-in-loop -->
<template>
  <div class="outersearch">
    <p class="title"></p>
    <div class="content">
      <div class="search">
        <div class="searchitem" v-for="item in reqFat" :key="item.id">
          <div class="searchtitle">{{ item.fieldName }}</div>
          <el-input
            class="searchinput"
            v-if="item.refField === 'input'"
            v-model="item.value"
          ></el-input>
          <el-input-number
            class="searchinput"
            v-if="item.refField === 'number'"
            v-model="item.value"
          ></el-input-number>
          <el-date-picker
            class="searchinput"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-if="item.refField === 'date'"
            v-model="item.value"
          ></el-date-picker>
          <el-date-picker
            class="searchinput"
           type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-if="item.refField === 'dateTime'"
            v-model="item.value"
          ></el-date-picker>
          <el-input
            class="searchinput"
            v-if="item.refField === 'radio'"
            v-model="item.value"
          ></el-input>
          <el-select
            filterable
            class="searchinput"
            :multiple="true"
            v-if="item.refField === 'select'"
            v-model="item.value"
            placeholder="请选择"
          >
            <el-option
              v-for="opt in item.options"
              :key="opt._key"
              :label="opt._txt"
              :value="opt._key"
            >
            </el-option>
          </el-select>

        </div>
        <div class="searchitem">
          <el-button size="small" type="primary" @click="onsearch">查询</el-button>
        </div>
      </div>
      <div class="listtable">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            v-for="item in dataSourceList"
            :key="item.id"
            :prop="item.fieldNameVariable"
            :label="item.fieldName"
          >
          </el-table-column>
        </el-table>
        <div class="pagecontainer">
          <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import interfaceService from "@/api/interface/index";
import { request } from "./http";

export default {
  components: {},
  data() {
    return {
      tableData: [],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      dataSourceList: [],
      req: [],
      reqFat: [],
      res: [],
      tablerequestInfo: {
        url: "",
        method: "POST"
      }
    };
  },
  methods: {
    findElementAndParents(tree, uuid) {
      const result = [];
      let current = "";
      const setparentId = (arr, id) => {
        arr.forEach((v) => {
          if (v.refField === uuid) {
            current = v;
          }
          v.parentId = id;
          if (v.children && v.children.length > 0) {
            setparentId(v.children, v.id);
          }
        });
      };

      const findRecursive = (arr, id) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of arr) {
          if (item.id === id) {
            // this.dataSourceList = item.children;
            result.push(item);
            if (item.parentId) {
              findRecursive(tree, item.parentId);
            }
            return;
          }
          if (item.children && item.children.length > 0) {
            findRecursive(item.children, id);
          }
        }
      };
      setparentId(tree, "");
      if (current) {
        findRecursive(tree, current.id);
      }

      return {
        current,
        parent: result.reverse()
      };
    },
    flatTree(arr) {
      const result = [];
      const flat = (item) => {
        result.push(item);
        if (item.children && item.children.length > 0) {
          flat(item.children);
        }
      };

      arr.forEach((v) => {
        flat(v);
      });
      return result;
    },
    async fetchdata(config) {
      const res = await request(config);

      const dataSource = this.findElementAndParents(this.res, "dataSource");
      this.dataSourceList = dataSource.current.children;
      const datamap = dataSource.parent.map((v) => v.fieldNameVariable);
      console.log(9090, datamap, res);
      let list = JSON.parse(JSON.stringify(res));
      datamap.forEach((v) => {
        list = list[v];
      });
      this.tableData = list;

      const currentPage = this.findElementAndParents(this.res, "currentPage");
      const currentPageMap = currentPage.parent.map((v) => v.fieldNameVariable);
      let currentPagedata = JSON.parse(JSON.stringify(res));

      currentPageMap.forEach((v) => {
        currentPagedata = currentPagedata[v];
      });

      this.currentPage = currentPagedata;

      const pageSize = this.findElementAndParents(this.res, "pageSize");
      const pageSizeMap = pageSize.parent.map((v) => v.fieldNameVariable);
      let pageSizedata = JSON.parse(JSON.stringify(res));

      pageSizeMap.forEach((v) => {
        pageSizedata = pageSizedata[v];
      });
      this.pageSize = pageSizedata;

      const totleSize = this.findElementAndParents(this.res, "totleSize");
      const totleSizeMap = totleSize.parent.map((v) => v.fieldNameVariable);
      let totleSizedata = JSON.parse(JSON.stringify(res));

      totleSizeMap.forEach((v) => {
        totleSizedata = totleSizedata[v];
      });
      this.total = totleSizedata;
    },

    async getInterface() {
      const resinterface = await interfaceService.queryInterfaceconfig({
        id: this.$route.query.id,
        type: 10
      });
      const config = JSON.parse(resinterface.externalConfig);
      this.tablerequestInfo.method = config.method || "POST";
      this.tablerequestInfo.url = config.url;
      this.res = config.res || [];
       this.req = config.req || [];
      const req = JSON.parse(JSON.stringify(this.req));
      const reqFat = this.flatTree(req);
      const searchreqFat = reqFat.filter((v) => !["pageSize", "currentPage"].includes(v.refField));

      for (let i = 0; i < searchreqFat.length; i++) {
        if (searchreqFat[i].refField === "select" || searchreqFat[i].refField === "radio") {
          if (searchreqFat[i].optionConfig.url) {
            // eslint-disable-next-line no-await-in-loop
            const optionres = await request({
              method: searchreqFat[i].optionConfig.method,
              data: {},
              url: searchreqFat[i].optionConfig.url
            });
            const dataSource = this.findElementAndParents(searchreqFat[i].optionConfig.rspParams, "dataSource");
            const datamap = dataSource.parent.map((v) => v.fieldNameVariable);
            console.log(9090, datamap, optionres);
            let list = JSON.parse(JSON.stringify(optionres));
            datamap.forEach((v) => {
              list = list[v];
            });
            const key = this.findElementAndParents(searchreqFat[i].optionConfig.rspParams, "key");
            const label = this.findElementAndParents(searchreqFat[i].optionConfig.rspParams, "label");
            key.current.fieldNameVariable;

            searchreqFat[i].options = list.map((v) => ({
              _txt: v[label.current.fieldNameVariable],
              _key: v[key.current.fieldNameVariable]
             }));
          }
        }
      }

      this.reqFat = searchreqFat;

      const option = reqFat.filter((v) => ["select", "radio"].includes(v.refField));

      this.fetchdata({
        method: this.tablerequestInfo.method,
        data: {},
        url: this.tablerequestInfo.url
      });
    },
    search(data) {
      this.fetchdata({
        method: this.tablerequestInfo.method,
        data,
        url: this.tablerequestInfo.url
      });
    },
    getval(item) {
      if (item.refField === "currentPage") return this.currentPage;
      if (item.refField === "pageSize") return this.pageSize;
      const currnet = this.reqFat.find((v) => v.id === item.id);
      if (currnet.refField === "select") {
        if (currnet.value.length === 0) {
          return null;
        }
      }
      return currnet.value || null;
    },
    getdatabody() {
      const o = {};
      this.req.forEach((v) => {
        o[v.fieldNameVariable] = this.getval(v);
      });
      return o;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.search(this.getdatabody());
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.search(this.getdatabody());
    },
    onsearch() {
      this.currentPage = 1;
      this.search(this.getdatabody());
    }
  },
  created() {
    this.getInterface();
  }
};
</script>

<style lang="scss" scoped>
.help-title {
  width: 100%;
  height: 50px;
  background-color: white;
  align-items: center;
  display: flex;
  border-radius: 5px;
}
.content {
  height: calc(100% - 50px);
  font-size: 12px;
}

.search {
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
}
.searchitem {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
}

.searchtitle {
  white-space: nowrap;
  margin-right: 10px;
}

.searchinput {
  width: 300px;
}
.pagecontainer{
  background-color: #fff;
  padding:20px;
  display: flex;
  justify-content: end;
}

</style>
