<template>
  <div id="setRole">
    <div class="set-title">
      <span class="role-name">
        {{getParamByKey('name')}}
      </span>
      <span>
        <button title="返回" @click="goback" id="GoBack" class="iconbtn iconbtn-back"></button>
      </span>
    </div>
    <div class="content">
      <div class="content-left">
        <div class="menuList" v-for="item in leftMenuList" :key="item.id" @click="changeMenu(item)">
          <div :class="{activeMenu:curMenul.id === item.id}" class="menu-tit">{{ item.name }}</div>
        </div>
      </div>
      <div class="content-right">
        <div class="top">
          <div class="top-left">
            <span class="title">菜单名称</span>
            <el-input size="small" v-model="input" placeholder=""></el-input>
            <el-button class="positon-btn" size="small" type="primary" @click="fixedDom">定位</el-button>
          </div>
          <el-button size="small" type="primary" @click="optbtn">{{ pageStatus === 1 ? '保存' : '编辑' }}</el-button>
        </div>
        <div class="tree-list">
          <el-tree :data="data" show-checkbox default-expand-all node-key="id" ref="tree" highlight-current
            :props="defaultProps">
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="custom-tree-node-tit" v-if="!data.node">{{ node.label }}</span>
              <div class="isSet" v-else>
                <div class="custom-tree-node-tit">{{ node.label }}</div>
                <div class="custom-tree-node-box">
                  <div class="set" @click="setOpen(node)">
                    <span style="padding-left: 5px;">
                      <svg t="1733391380797" v-if="data.node.setting.dataRanges" class="icon" viewBox="0 0 1024 1024"
                           version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2737" width="14" height="14">
                        <path
                                d="M760.896 960H263.104A199.104 199.104 0 0 1 64 760.896V263.104A199.104 199.104
                                 0 0 1 263.104 64h497.728A199.104 199.104 0 0 1 960 263.104v497.728A199.104 199.104
                                  0 0 1 760.896 960z m149.376-497.792h-248.96v-49.792h248.96V263.104a149.376 149.376
                                   0 0 0-149.376-149.312H462.208V412.48h-49.792V113.792H263.104a149.312 149.312 0
                                    0 0-149.312 149.312v298.688h248.896v49.792H113.792v149.312a149.376 149.376 0 0
                                     0 149.312 149.376H611.52V611.584h49.728v298.688h99.584a149.44 149.44 0 0 0 149.376-149.376V462.208z"
                                fill="#1296db" p-id="2738"></path>
                      </svg>
                      <svg v-else t="1733385552580" class="icon" viewBox="0 0 1024 1024" version="1.1"
                           xmlns="http://www.w3.org/2000/svg" p-id="2890" width="14" height="14">
                        <path
                                d="M760.896 960H263.104A199.104 199.104 0 0 1 64 760.896V263.104A199.104 199.104
                                 0 0 1 263.104 64h497.728A199.104 199.104 0 0 1 960 263.104v497.728A199.104 199.104
                                  0 0 1 760.896 960z m149.376-497.792h-248.96v-49.792h248.96V263.104a149.376 149.376
                                   0 0 0-149.376-149.312H462.208V412.48h-49.792V113.792H263.104a149.312 149.312 0
                                    0 0-149.312 149.312v298.688h248.896v49.792H113.792v149.312a149.376 149.376 0
                                     0 0 149.312 149.376H611.52V611.584h49.728v298.688h99.584a149.44 149.44 0 0 0 149.376-149.376V462.208z"
                                fill="#e6e6e6" p-id="2891"></path>
                      </svg>
                      <svg v-if="data.node.setting.powerPic" t="1733390915106" class="icon" viewBox="0 0 1024 1024"
                           version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1468" width="14" height="14">
                        <path
                                d="M511.994931 5.089584C232.027248 5.089584 5.069307 232.042455 5.069307 512.005069 5.069307
                                 791.977822 232.027248 1018.930693 511.994931 1018.930693 791.977822 1018.930693 1018.930693
                                  791.977822 1018.930693 512.005069 1018.930693 232.042455 791.977822 5.089584 511.994931
                                   5.089584L511.994931 5.089584zM511.994931 979.937584c-258.438337 0-467.932515-209.47897-467.932515-467.937584
                                    0-258.428198 209.494178-467.922376 467.932515-467.922376 258.428198 0 467.937584 209.494178 467.937584
                                     467.922376C979.937584 770.463683 770.423129 979.937584 511.994931 979.937584L511.994931 979.937584zM511.994931 979.937584"
                                fill="#1296db" p-id="1469"></path>
                        <path
                                d="M730.720317 587.000396c0-11.137267-3.710733-20.682772-11.664475-29.163723l-227.480079-226.952871c-7.953743-7
                                .953743-19.09101-14.848-32.347248-20.682772-13.788515-5.829703-25.980198-8.48095-37.117465-8.48095l-132.562376 0c-11.137267
                                 0-20.677703 4.24301-28.636515 12.196752-7.953743 7.953743-12.196752 17.499248-12.196752 28.636515l0 132.035168c0 11.137267
                                  2.651248 23.861228 8.48095 37.117465 5.834772 13.788515 12.196752 24.393505 20.682772 32.347248l227.480079 227.480079c7.953743
                                   7.953743 17.499248 11.664475 28.631446 11.664475 11.137267 0 20.677703-3.710733 29.163723-11.664475l155.896396-155.896396C726.482376
                                    607.678099 730.720317 598.137663 730.720317 587.000396L730.720317 587.000396 730.720317 587.000396zM379.158812 432.695762c-7.953743
                                     7.953743-17.494178 11.664475-28.631446 11.664475-11.137267 0-20.677703-3.710733-28.631446-11.664475-7.953743-7.953743-11.669545-17.494178-11.669545-28.636515
                                      0-11.137267 3.710733-20.677703 11.669545-28.631446 7.953743-7.953743 17.494178-11.669545 28.631446-11.669545 11.137267 0 20.682772 3.710733 28.631446
                                       11.669545 7.953743 7.953743 11.664475 17.494178 11.664475 28.631446C391.355564 415.196515 387.117624 424.74202 379.158812 432.695762L379.158812
                                        432.695762 379.158812 432.695762zM841.018297 557.836673l-227.480079-226.952871c-7.953743-7.953743-19.09101-14.848-32.347248-20.682772-13.788515-5.829703-25.980198-8.48095-37.117465-8.48095L472.484752 301.720079c11.137267
                                         0 23.866297 2.651248 37.122535 8.48095 13.788515 5.834772 24.388436 12.196752 32.342178 20.682772l227.480079 226.952871c7.953743
                                          8.48095 11.669545 18.031525 11.669545 29.163723 0 11.137267-3.710733 20.677703-11.669545 28.636515l-149.002139 149.534416c6.36198
                                           6.36198 12.196752 11.137267 16.96697 14.315723 4.775287 3.178455 11.137267 4.237941 18.558733 4.237941 11.137267 0 20.677703-3.710733
                                            29.163723-11.664475l155.896396-156.423604c7.953743-7.953743 11.664475-17.499248 11.664475-28.636515C852.682772 575.863129
                                             848.97204 566.317624 841.018297 557.836673L841.018297 557.836673 841.018297 557.836673zM841.018297 557.836673"
                                fill="#1296db" p-id="1470"></path>
                      </svg>
                      <svg v-else t="1733390915106" class="icon" viewBox="0 0 1024 1024" version="1.1"
                           xmlns="http://www.w3.org/2000/svg" p-id="1468" width="14" height="14">
                        <path
                                d="M511.994931 5.089584C232.027248 5.089584 5.069307 232.042455 5.069307 512.005069 5.069307 791.977822 232.027248 1018.930693
                                 511.994931 1018.930693 791.977822 1018.930693 1018.930693 791.977822 1018.930693 512.005069 1018.930693 232.042455 791.977822
                                  5.089584 511.994931 5.089584L511.994931 5.089584zM511.994931 979.937584c-258.438337 0-467.932515-209.47897-467.932515-467.937584
                                   0-258.428198 209.494178-467.922376 467.932515-467.922376 258.428198 0 467.937584 209.494178 467.937584 467.922376C979.937584
                                    770.463683 770.423129 979.937584 511.994931 979.937584L511.994931 979.937584zM511.994931 979.937584"
                                fill="#e6e6e6" p-id="1469"></path>
                        <path
                                d="M730.720317 587.000396c0-11.137267-3.710733-20.682772-11.664475-29.163723l-227.480079-226.952871c-7.953743-7.953743-19.09101-14.848-32.347248-20.682772-13.788515-5.829703-25.980198-8.48095-37.117465-8.48095l-132.562376
                                 0c-11.137267 0-20.677703 4.24301-28.636515 12.196752-7.953743 7.953743-12.196752 17.499248-12.196752 28.636515l0
                                  132.035168c0 11.137267 2.651248 23.861228 8.48095 37.117465 5.834772 13.788515 12.196752 24.393505 20.682772
                                   32.347248l227.480079 227.480079c7.953743 7.953743 17.499248 11.664475 28.631446 11.664475 11.137267 0
                                    20.677703-3.710733 29.163723-11.664475l155.896396-155.896396C726.482376 607.678099 730.720317 598.137663
                                     730.720317 587.000396L730.720317 587.000396 730.720317 587.000396zM379.158812 432.695762c-7.953743 7.953743-17.494178
                                      11.664475-28.631446 11.664475-11.137267 0-20.677703-3.710733-28.631446-11.664475-7.953743-7.953743-11.669545-17.494178-11.669545-28.636515
                                       0-11.137267 3.710733-20.677703 11.669545-28.631446 7.953743-7.953743 17.494178-11.669545 28.631446-11.669545 11.137267
                                        0 20.682772 3.710733 28.631446 11.669545 7.953743 7.953743 11.664475 17.494178 11.664475 28.631446C391.355564
                                         415.196515 387.117624 424.74202 379.158812 432.695762L379.158812 432.695762 379.158812 432.695762zM841.018297
                                          557.836673l-227.480079-226.952871c-7.953743-7.953743-19.09101-14.848-32.347248-20.682772-13.788515-5.829703-25.980198-8.48095-37.117465
                                          -8.48095L472.484752 301.720079c11.137267 0 23.866297 2.651248 37.122535 8.48095 13.788515 5.834772 24.388436
                                           12.196752 32.342178 20.682772l227.480079 226.952871c7.953743 8.48095 11.669545 18.031525 11.669545 29.163723 0
                                            11.137267-3.710733 20.677703-11.669545 28.636515l-149.002139 149.534416c6.36198 6.36198 12.196752 11.137267
                                             16.96697 14.315723 4.775287 3.178455 11.137267 4.237941 18.558733 4.237941 11.137267 0 20.677703-3.710733
                                              29.163723-11.664475l155.896396-156.423604c7.953743-7.953743 11.664475-17.499248 11.664475-28.636515C852.682772
                                               575.863129 848.97204 566.317624 841.018297 557.836673L841.018297 557.836673 841.018297 557.836673zM841.018297 557.836673"
                                fill="#e6e6e6" p-id="1470"></path>
                      </svg>
                      <svg v-if="data.node.setting.tabSetPower" t="1733391095959" class="icon" viewBox="0 0 1273 1024"
                           version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1479" width="14" height="14">
                        <path
                                d="M1273.6 1024H0V0h590.933333l184.533334 253.866667h498.133333zM106.666667 913.066667h1056V363.733333H720L533.333333 106.666667H106.666667z"
                                p-id="1480" fill="#1296db"></path>
                        <path
                                d="M974.933333 226.133333H817.066667L657.066667 0h156.8zM981.333333 0l160 226.133333h-100.266666L881.066667
                                 0m231.466666 0l160 226.133333h-62.933333L1048.533333 0M213.333333 516.266667h77.866667v308.266666h71.466667V516.266667h76.8v
                                 -76.8H213.333333z m309.333334 0h148.266666v76.8H517.333333v231.466666H746.666667V439.466667H517.333333zM670.933333
                                  746.666667h-76.8v-75.733334h76.8z m231.466667-153.6V439.466667h-76.8v385.066666h231.466667V593.066667zM980.266667
                                   746.666667h-77.866667v-75.733334h77.866667z"
                                p-id="1481" fill="#1296db"></path>
                      </svg>
                      <svg v-else t="1733391095959" class="icon" viewBox="0 0 1273 1024" version="1.1"
                           xmlns="http://www.w3.org/2000/svg" p-id="1479" width="14" height="14">
                        <path
                                d="M1273.6 1024H0V0h590.933333l184.533334 253.866667h498.133333zM106.666667 913.066667h1056V363.733333H720L533.333333 106.666667H106.666667z"
                                p-id="1480" fill="#e6e6e6"></path>
                        <path
                                d="M974.933333 226.133333H817.066667L657.066667 0h156.8zM981.333333 0l160 226.133333h-100.266666L881.066667
                                 0m231.466666 0l160 226.133333h-62.933333L1048.533333 0M213.333333 516.266667h77.866667v308.266666h71.466667V516
                                 .266667h76.8v-76.8H213.333333z m309.333334 0h148.266666v76.8H517.333333v231.466666H746.666667V439.466667H517
                                 .333333zM670.933333 746.666667h-76.8v-75.733334h76.8z m231.466667-153.6V439.466667h-76
                                 .8v385.066666h231.466667V593.066667zM980.266667 746.666667h-77.866667v-75.733334h77.866667z"
                                p-id="1481" fill="#e6e6e6"></path>
                      </svg>
                    </span>
                    <span>设置</span>
                  </div>
                  <span style="padding:0 5px;" :style="{color: pageStatus === 1 ? '#4da8ff' : '#C0C4CC'}" class="select"
                           @click="selectBtn(data.node )">{{
                             isSelect(data.node.checkList,data.node.btns) }}</span>
                  <div class="checkbox-box">
                    <el-checkbox-group v-model="data.node.checkList" :disabled="pageStatus === 2"
                      @change="handleCheckedChange($event,data.node)">
                      <el-checkbox v-for="it in data.node.btns" :label="it.btnKey" :key="it.btnKey">{{it.btnName}}</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
              </div>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
    <el-dialog title="设置更多权限" :visible.sync="dialogSetVisible" width="90%" top="5vh">
      <div :key="count" class="boyd-dialog">
        <div class="wrapper wrapper-content animated fadeInRight" id="add-wrap">
          <div class="general-warp">
            <div class="general-centbar">
              <div class="megapower-set-box">
                <div class="megapower-set">
                  <div class="megapower-basic-box">
                    <div class="row-maintit"><span class="txt">操作权限</span></div>
                    <div class="megapower-basic">
                      <div class="row-sele" id="function-wrap">
                      </div>
                    </div>
                  </div>
                  <div class="megapower-basic-box">
                    <div class="row-maintit"><span class="txt">看板权限</span></div>
                    <div class="megapower-basic">
                      <div class="row-sele" id="chart-wrap">
                      </div>
                    </div>
                  </div>
                  <div class="megapower-basic-box">
                    <div class="row-maintit"><span class="txt">页签权限</span></div>
                    <div class="megapower-basic">
                      <div class="row-sele" id="tab-wrap">
                      </div>
                    </div>
                  </div>
                  <div class="megapower-basic-box">
                    <div class="row-maintit"><span class="txt">字段数据范围</span>
                      <div class="col-seticons">
                        <div class="ui-temp-check">
                          <div class="comm-btnset" id="btn_setrangepower"><i class="icon-setrangepower"></i><span
                              class="txts">设置</span></div>
                          <div class="ui-temp-notice hide">
                            <div class="notice-main">
                              <i class="notice-icon"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row-main" id="show-setrangepower">
                        <div class="engrid-main engrid-main-general">
                          <div class="engrid-warp">
                            <div class="engrid-lis">
                              <div class="engrid-header">
                                <div class="header-mod cell-width">
                                  <div class="col-main">
                                    <div class="col-txt"><span>字段名称</span></div>
                                  </div>
                                </div>
                                <div class="header-mod cell-width">
                                  <div class="col-main">
                                    <div class="col-txt"><span>数据范围</span></div>
                                  </div>
                                </div>
                              </div>
                              <div class="engrid-center" id="list-grid-wrap">
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="set-filed"></div>
                    </div>
                  </div>
                  <div class="megapower-basic-box">
                    <div class="row-maintit">
                      <div class="pull-left"><span class="txt">字段控制</span></div>
                      <div class="pull-right hide">
                        <!--'cur 展开'-->
                        <div class="btn-showmore" title="展开"></div>
                      </div>
                    </div>
                    <div class="mod-treegrid field-control" id="field-control-wrap">
                      <dl>
                        <dt class="treegrid-head">
                          <div class="cell-mod cell-level">
                            <div class="col-main">
                              <div class="col-txt"><span>字段名称</span></div>
                            </div>
                          </div>
                          <div class="cell-mod">
                            <div class="col-main">
                              <div class="comm-chk-content">
                                <div class="comm-chk-wrap">
                                  <div class="comm-chk comm-chk-bg" id="chk-addShow-all"></div>
                                  <div class="comm-label">新增</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="cell-mod">
                            <div class="col-main">
                              <div class="comm-chk-content">
                                <div class="comm-chk-wrap">
                                  <div class="comm-chk comm-chk-bg" id="chk-see-all"></div>
                                  <div class="comm-label">查看</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="cell-mod">
                            <div class="col-main">
                              <div class="comm-chk-content">
                                <div class="comm-chk-wrap">
                                  <div class="comm-chk" id="chk-edit-all"></div>
                                  <div class="comm-label">编辑</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- v4.9.9紧急 -->
                          <div class="cell-mod">
                            <div class="col-main">
                              <div class="comm-chk-content">
                                <div class="comm-chk-wrap">
                                  <div class="comm-chk" id="chk-download-all"></div>
                                  <div class="comm-label">下载</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </dt>
                        <dd class="treegrid-cent">
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="savePower">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { userRoleService } from "@/api";

export default {
  data() {
    return {
      curType: "",
      cssList: [
        {
          name: "bootstrap",
          value: "bootstrap.min",
          cssModule: null
        },
        {
          name: "business",
          value: "business-min",
          cssModule: null
        }, {
            name: "enfryStyle",
          value: "enfryStyle-min",
          cssModule: null
        }, {
          name: "jquery",
          value: "jquery-ui-1.10.4.custom.min",
          cssModule: null
        }
      ],
      saveParams: {},
        dialogSetVisible: false,
        count: 0,
        currentMatchIndex: 0,
        checkedKeys: [],
        checkList: [],
        data: [],
        defaultProps: {
          children: "nodes",
          label: "name",
          disabled: "disabled"
        },
        input: "",
        leftMenuList: [],
        curMenul: {},
        pageStatus: 2 // 1:保存，2：编辑
    };
  },
  computed: {
      isSelect() {
          return (selectBtns, btns) => (selectBtns.length === btns.length ? "取消全选" : "全部选择");
      }
  },
  methods: {
      selectBtn(node) {
          const { checkList, btns } = node;
          if (checkList.length === btns.length) {
              node.checkList = [];
          } else {
              node.checkList = [];
              btns.forEach((item) => {
                  node.checkList.push(item.btnKey);
              });
          }
      },
    goback() {
      const type = this.getParamByKey("type");
        if (type === "role") {
          this.$router.push({
                path: "/userRole",
                query: {
                    type: "role"
                }
            });
        } else {
          this.$router.push({
                path: "/userRole",
                query: {
                    type: "staff"
                }
            });
        }
    },
    async loadCSS() {
      this.cssList.forEach((item) => {
        item.cssModule = document.createElement("link");
        item.cssModule.rel = "stylesheet";
        item.cssModule.href = `/css/${item.value}.css`;
        document.head.appendChild(item.cssModule);
      });
    },
      savePower() {
        if (this.curType !== 1) {
          // eslint-disable-next-line no-undef
          MP.save(() => {
            this.dialogSetVisible = false;
            this.pageStatus = 2;
            this.getRoleTree();
          });
        } else {
          // eslint-disable-next-line no-undef
          BASEMP.save(() => {
            this.dialogSetVisible = false;
            this.pageStatus = 2;
            this.getRoleTree();
          });
        }
      },
      // 网址中获取参数值
      getParamByKey(key, url) {
        const locString = url || location.search;
        const reg = new RegExp(`(\\?|\\&)${ key }=([^\\&]*)(\\&?)`, "i").exec(
          locString
        );
        if (reg != null) {
          return decodeURI(RegExp.$2);
        }
          return "";
      },
      handleCheckedChange(value, node) {
        console.log(value, node);
      },
      deepData(list) {
        list.forEach((ele, index) => {
          ele.disabled = false;
          if (ele.nodes?.length > 0) {
            this.deepData(ele.nodes);
          }
        });
        this.data = list;
      },
      getSelectKey(list) {
        if (list.length > 0) {
          list.forEach((ele, index) => {
            if (ele.node?.btns) {
              ele.node.checkList = [];
              ele.node?.btns.forEach((it) => {
                if (it.isCheck) {
                  ele.node.checkList.push(it.btnKey);
                }
              });
            }
            if (ele.enableFlag === "000") {
              this.checkedKeys.push(ele.id);
            }
            if (this.pageStatus === 2) {
              ele.disabled = true;
            }
            if (ele.nodes?.length > 0) {
              this.getSelectKey(ele.nodes);
            }
          });
        }
        this.data = list;
        this.$refs.tree.setCheckedKeys(this.checkedKeys);
      },
      async getRoleTree() {
        console.time("后端处理");
        const res = await userRoleService.getRoleMenuTree({
            query: "001",
            id: this.curMenul.id,
            roleId: this.getParamByKey("id"),
            isRbac: "000",
            levelCode: this.curMenul.levelCode
          });
        this.data = [];
        console.timeEnd("后端处理");
        if (res) {
          console.time("前端处理");
          this.getSelectKey(res);
          console.timeEnd("前端处理");
        }
      },
      setSaveParams() {
        const arr = this.$refs.tree.getCheckedNodes();
        this.saveParams = {
          roleId: this.getParamByKey("id"),
          type: this.curMenul.id,
          saveData: JSON.stringify(arr.map((it) => ({
              value: it.id,
              type: this.curMenul.id,
              roleId: this.getParamByKey("id"),
              enableFlag: it.enableFlag,
              funcBtns: it.node?.checkList?.join(",") || ""
            })))
        };
      },
      async optbtn() {
        if (this.pageStatus === 1) {
          // 保存
          this.setSaveParams();
          await this.save();
          this.pageStatus = this.pageStatus === 2 ? 1 : 2;
        } else {
          const arr = JSON.parse(JSON.stringify(this.data));
          this.deepData(arr);
          this.pageStatus = this.pageStatus === 2 ? 1 : 2;
        }
      },
      async save() {
        const res = await userRoleService.saveSetModel(this.saveParams);
        this.getRoleTree();
      },
      fixedDom() {
        const dom = document.querySelectorAll(".custom-tree-node-tit");
        let foundMatch = "";
        for (let i = this.currentMatchIndex; i < dom.length; i++) {
          if (dom[i].innerHTML.includes(this.input)) {
            dom[i].scrollIntoView({ behavior: "smooth", block: "center" });
            foundMatch = true;
            this.currentMatchIndex = i + 1;
            break;
          }
        }
        if (!foundMatch) {
          this.currentMatchIndex = 0;
        }
      },
      changeMenu(item) {
        this.curMenul = item;
        this.getRoleTree();
      },
      async setOpen(itemNode) {
        console.log(itemNode);
        this.curType = itemNode.data.type;
        if (this.pageStatus === 2) return;
        // eslint-disable-next-line no-undef
        MP.templateId = itemNode.data.id;
         // eslint-disable-next-line no-undef
        BASEMP.templateId = itemNode.data.id;

        this.dialogSetVisible = true;
        setTimeout(() => {
          if (itemNode.data.type !== 1) {
             // eslint-disable-next-line no-undef
            MP.init();
          } else {
                  // eslint-disable-next-line no-undef
            BASEMP.refId = itemNode.data.objectTypeId;
            // eslint-disable-next-line no-undef
            BASEMP.init();
          }

          this.count++;
        }, 500);
      },
      async getLeftMenuList() {
        const res = await userRoleService.getRoleLeftTree({
            query: "000",
            classify: "000"
            // submitToken: '0298bd4b-370d-4bf0-8346-8d85b372f3c5',
            // tenantId: '417340298122756096'
          });
        if (res) {
          this.leftMenuList = res;
          // 找出 业务处理 597614676311277568
          const model = this.leftMenuList.find((it) => it.id === "597614676311277568");
          if (model.name) {
              this.changeMenu(model);
          }
        }
      }
    },
    async mounted() {
      this.getLeftMenuList();
    },
    created() {
      this.loadCSS();
    },
    beforeDestroy() {
    this.cssList.forEach((item) => {
      if (item.cssModule) {
        document.head.removeChild(item.cssModule);
      }
    });
  }
};
</script>
<style lang="scss">
    #setRole {
      width: 100%;
      height: 100%;
      .set-title {
            border-radius: 6px;
            display: flex;
            align-items: center;
            padding: 15px;
            justify-content: space-between;
            background: #fff;
        }

        .role-name {
            font-size: 20px;
        }

        .content {
            margin-top: 10px;
            display: flex;
            height: calc(100% - 90px);
        }

        .content-left {
            background: #fff;
            width: 318px;
            padding: 10px;
            height: 100%;
            border-right: 1px solid #f3f3f3;
            overflow-y: auto;
            flex-shrink: 0;
            margin-right: 10px;
            text-align: center;
        }

        .menuList {
            margin-bottom: 10px;
            cursor: pointer;
            position: relative;
            border: 1px solid #eee;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-radius: 5px;
            text-align: left;
            font-size: 12px;
            color: #606060;
            cursor: pointer;
        }

        .menuList:hover {
            background: #f7f7f7;
        }

        .menu-tit {
            padding-left: 30px;
            width: 100%;
            height: 36px;
            line-height: 36px;
        }

        .activeMenu {
            background: #179eda;
            color: #fff;
        }

        .content-right {
            background: #fff;
            flex: 1;
            padding: 0px 20px 20px 20px;
            overflow: auto;
        }

        .top {
            display: flex;
            justify-content: space-between;
            position: sticky;
            padding-top: 20px;
            top: 0;
            z-index: 101;
        }

        .top-left {
            display: flex;
            align-items: center;
        }

        .title {
            margin-right: 10px;
            width: 120px;

            font-size: 14px;
        }

        .positon-btn {
            margin-left: -10px;
            position: relative;
            z-index: 10;
        }

        .tree-list {
            margin-top: 20px;
        }

        .custom-tree-node-tit {
            font-size: 12px;
        }

        .custom-tree-node-box {
            display: flex;
            align-items: baseline;
        }

        .el-checkbox__label {
            font-size: 12px;
            padding-left: 5px;
        }

        .el-checkbox {
            margin-right: 15px;
        }

        .set {
            margin-right: 10px;
            display: flex;
            align-items: center;
            font-size: 12px;
        }

        .set i {
            vertical-align: middle;

        }

        .el-tree-node__content {
            height: auto;
            display: flex;
            align-items: baseline;
        }

        .custom-tree-node-box {
            margin: 5px 0;
        }

        .opt-box {
            border-top: 1px solid #f3f3f3;
            padding: 20px 0;
        }

        .dialog-tit {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 15px
        }

        #saveFeild {
            width: 100%;
            display: flex;
            justify-content: end;
            margin-bottom: 15px;
        }

        .savebtn {
            background-color: rgb(3, 138, 196);
            border: none;
            border-radius: 6px;
            width: 95px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            font-size: 12px;
            color: rgb(255, 255, 255);
            cursor: pointer;
        }

        .boyd-dialog {
            height: 900px;
            overflow: auto;
        }

        .checkbox-box .el-checkbox-group {
            width: 500px;
            display: flex;
            flex-wrap: wrap;
        }

        .set svg {
            vertical-align: text-top;
            margin-right: 3px;
        }
  }
  </style>
