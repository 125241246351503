/*
 * @Description: 帮助文档路由
 * @Author: 张小兵
 * @Date: 2020-07-23 10:57:40
 */

const routes = [
  {
    path: "/help",
    component: () => import(/* webpackChunkName: "help" */ "@/views/help/index"),
    meta: {
      title: "帮助中心",
      fullPage: false,
      keepAlive: true
    }
  },
  {
    path: "/outersearch",
    component: () => import(/* webpackChunkName: "help" */ "@/views/outersearch/index"),
    meta: {
      title: "外查接口",
      fullPage: false,
      keepAlive: true
    }
  }
];

export default routes;
