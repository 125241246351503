/*
 * @Descripttion: 控件集 抛出
 * @Author: 彭博
 * @Date: 2020-07-24 17:26:20
 * @LastEditors: linshanfeng
 * @LastEditTime: 2024-01-30 13:54:44
 */
import * as ColumnComponent from "./src";
import { BusinessFieldType } from "../../data/BusinessCommonHeader.js";

export const components = {
  BMTextColumn: () => import("./text/BMTextColumn"),
  BMSeeFlowColumn: () => import("./seeFlow"),
  BMFlowColumn: () => import("./flow"),
  BMLogColumn: () => import("./log"),
  BMNoticeReceiptColumn: () => import("./noticeReceipt"),
  BMCommentColumn: () => import("./comment"),
  BMNotifierColumn: () => import("./notifier"),
  BMApprovalCommentColumn: () => import("./approvalComment"),
  BMSelectColumn: () => import("./select/BMSelectColumn"),
  BMDateColumn: () => import("./date"),
  BMAddressColumn: () => import("./address"),
  BMTimeAxisColumn: () => import("./timeAxis"),
  BMCashObject: () => import("./cashObject"),
  BMProgressColumn: () => import("./progress"),
  BMMarkCompleteColumn: () => import("./markComplete"),
  BMSubItemColumn: () => import("./subItem"),
  BMInstructionColumn: () => import("./instruction"),
  BMSeperatorLineComponent: () => import("./line"),
  BMSignColumn: () => import("./sign"),
  BMAttachColumn: () => import("./attachment"),
  BMTextComposeColumn: () => import("./textComposition"),
  BMDetailComponent: () => import("./detail/BMDetailComponent"),
  BMSubsetComponent: () => import("./subSet"),
  BMOCRComponent: () => import("./ocr"),
  BMAssociatedComponent: () => import("./associated"),
  BMMultiTabComponent: () => import("./multiTab"),
  BMMileStoneComponent: () => import("./mileStone"),
  BMAreaComponent: () => import("./area"),
  // 后面的都待删除
  "bm-input": ColumnComponent.BMInputComponent,
  "bm-multi-input": ColumnComponent.BMMultiInputComponent,
  "bm-number": ColumnComponent.BMNumberComponent,
  "bm-image": ColumnComponent.BMImageComponent
};

export const renderingComponent = (fieldType) => {
  switch (fieldType) {
    case BusinessFieldType.inputCommon:
    case BusinessFieldType.multiText:
    case BusinessFieldType.inputNumber:
    case BusinessFieldType.inputAmount:
      return "BMTextColumn";
    case BusinessFieldType.notifier:
      return "BMNotifierColumn";
    case BusinessFieldType.seeFlow:
      return "BMSeeFlowColumn";
    case BusinessFieldType.flow:
      return "BMFlowColumn";
    case BusinessFieldType.comment:
      return "BMCommentColumn";
    case BusinessFieldType.approvalComment:
      return "BMApprovalCommentColumn";
    case BusinessFieldType.log:
      return "BMLogColumn";
    case BusinessFieldType.pureText:
      return "BMInstructionColumn";
    case BusinessFieldType.detailParent:
      return "BMDetailComponent";
    case BusinessFieldType.line:
      return "BMSeperatorLineComponent";
    case BusinessFieldType.cashObject:
      return "BMCashObject";
    case BusinessFieldType.shouFu:
      return "BMDetailComponent";
    case BusinessFieldType.address:
      return "BMAddressColumn";
    case BusinessFieldType.subItem:
      return "BMSubItemColumn";
    case BusinessFieldType.ocr:
      return "BMOCRComponent";
    case BusinessFieldType.newProgress:
      return "BMProgressColumn";
    case BusinessFieldType.attachment:
      return "BMAttachColumn";
    case BusinessFieldType.checkBox:
      return "BMMarkCompleteColumn";
    case BusinessFieldType.subSet:
      return "BMSubsetComponent";
    case BusinessFieldType.select:
      case BusinessFieldType.superior:
    case BusinessFieldType.multiSelect:
      return "BMSelectColumn";
    case BusinessFieldType.date:
      return "BMDateColumn";
    case BusinessFieldType.timeAxis:
      return "BMTimeAxisColumn";
    case BusinessFieldType.sign:
      return "BMSignColumn";
    case BusinessFieldType.textComposition:
      return "BMTextComposeColumn";
    case BusinessFieldType.associatedData:
      return "BMAssociatedComponent";
    case BusinessFieldType.multiTab:
      return "BMMultiTabComponent";
    case BusinessFieldType.mileStone:
      return "BMMileStoneComponent";
    case BusinessFieldType.area:
      return "BMAreaComponent";
    case BusinessFieldType.noticeReceipt:
      return "BMNoticeReceiptColumn";
    case BusinessFieldType.imageAttachment:
      return "bm-image";
    default:
      return "bm-input";
  }
};
