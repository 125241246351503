var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bm-select-column" },
    [
      _vm._v(" " + _vm._s(_vm.isAutoAssign) + " "),
      _vm.isAutoAssign
        ? _c("el-input", {
            attrs: { placeholder: "请选择" },
            on: { focus: _vm.openPopAutoAssign },
            model: {
              value: _vm.columnShowValue,
              callback: function ($$v) {
                _vm.columnShowValue = $$v
              },
              expression: "columnShowValue",
            },
          })
        : _vm.popoverStyle
        ? _c(
            "el-popover",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.handleClickOutSide,
                  expression: "handleClickOutSide",
                },
              ],
              attrs: {
                placement: "bottom-start",
                trigger: "manual",
                value: _vm.showAssistViewDialog,
                "popper-class": "select-assist-view",
              },
            },
            [
              _c(
                "div",
                { staticClass: "select-assist-wrap" },
                _vm._l(_vm.column.assistView, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "assist-view-item" },
                    [
                      _c("span", { staticClass: "dotted" }),
                      _c("span", [
                        _vm._v(
                          _vm._s(item.name) +
                            ": " +
                            _vm._s(_vm.getAssistViewValue(item))
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticClass: "model-select focus-within",
                  class: {
                    "has-right": _vm.hasRightBtn,
                    "is-focus": _vm.isFocus,
                    "is-disabled": _vm.isDisabled,
                  },
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("en-select", {
                    ref: "selectControl",
                    style: [
                      _vm.bindStyle,
                      _vm.nodeStyle,
                      _vm.inputStyle(_vm.column.field, _vm.column.areaObjType),
                    ],
                    attrs: {
                      id: _vm.column.field,
                      placeholder: _vm.column.showPlaceholder,
                      "check-mode": "normal",
                      mode: _vm.column.selectMode,
                      data: _vm.options,
                      props: _vm.column.defaultProps,
                      disabled: _vm.column.disabled || !_vm.column.editable,
                      filterable: _vm.column.filterable,
                      align: "left",
                      "multiple-limit": _vm.column.multipleLimit,
                      "thum-tag": _vm.column.thumTag,
                      "data-mode": "data",
                      assist:
                        _vm.column.assistView &&
                        _vm.column.assistView.length > 0,
                      "allow-create": _vm.column.allowCreate,
                      multiple: _vm.isMultiSelect,
                      clearable: _vm.isMultiSelect,
                      lazy: _vm.lazy,
                      isPeople: _vm.isPeople,
                      load: _vm.treeLoad,
                      "page-size": _vm.pageSize,
                      loading: _vm.loading,
                      "allow-visible": _vm.canOpenSelect,
                      remote: _vm.column.remote,
                      "remote-method": _vm.remoteMethod,
                      showTexy: _vm.isShowText(),
                      "custom-option-add": !!_vm.column.areaObjType,
                    },
                    on: {
                      change: _vm.onChange,
                      "visible-change": _vm.visibleChange,
                      clear: _vm.clearMultiSelect,
                      "not-allow-visible": _vm.onNotAllowVisible,
                      "custom-option-add-method": _vm.onCustomOptionAddMethod,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "assist",
                        fn: function ({ data }) {
                          return [
                            _c(
                              "span",
                              { staticClass: "custom-assist" },
                              _vm._l(
                                _vm.column.assistSelect,
                                function (item, index) {
                                  return _c(
                                    "span",
                                    { key: index, staticClass: "assist-block" },
                                    [
                                      data[item.field]
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(item.name) +
                                                ": " +
                                                _vm._s(data[item.field])
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.column.showValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.column, "showValue", $$v)
                      },
                      expression: "column.showValue",
                    },
                  }),
                  _vm.hasRightBtn
                    ? _c("RightAddition", {
                        attrs: {
                          column: _vm.column,
                          "row-index": _vm.rowIndex,
                          "business-data": _vm.businessData,
                        },
                        on: { handleAssitViewClick: _vm.handleAssitViewClick },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "model-select focus-within",
              class: {
                "has-right": _vm.hasRightBtn,
                "is-focus": _vm.isFocus,
                "is-disabled": _vm.isDisabled,
              },
            },
            [
              _c("en-select", {
                ref: "selectControl",
                style: [
                  _vm.bindStyle,
                  _vm.nodeStyle,
                  _vm.inputStyle(_vm.column.field, _vm.column.areaObjType),
                ],
                attrs: {
                  id: _vm.column.field,
                  placeholder: _vm.column.showPlaceholder,
                  "check-mode": "normal",
                  mode: _vm.column.selectMode,
                  data: _vm.options,
                  props: _vm.column.defaultProps,
                  disabled: _vm.column.disabled || !_vm.column.editable,
                  filterable: _vm.column.filterable,
                  align: "left",
                  "multiple-limit": _vm.column.multipleLimit,
                  "thum-tag": _vm.column.thumTag,
                  "data-mode": "data",
                  assist:
                    _vm.column.assistView && _vm.column.assistView.length > 0,
                  "allow-create": _vm.column.allowCreate,
                  multiple: _vm.isMultiSelect,
                  clearable: _vm.isMultiSelect,
                  lazy: _vm.lazy,
                  load: _vm.treeLoad,
                  "page-size": _vm.pageSize,
                  loading: _vm.loading,
                  "allow-visible": _vm.canOpenSelect,
                  remote: _vm.column.remote,
                  "remote-method": _vm.remoteMethod,
                  "fuzzy-search": false,
                  tabEdit: _vm.tabEdit,
                  isPeople: _vm.isPeople,
                  showTexy: _vm.isShowText(),
                  "custom-option-add": !!_vm.column.areaObjType,
                },
                on: {
                  change: _vm.onChange,
                  "visible-change": _vm.visibleChange,
                  clear: _vm.clearMultiSelect,
                  selectSure: _vm.selectSure,
                  "not-allow-visible": _vm.onNotAllowVisible,
                  "custom-option-add-method": _vm.onCustomOptionAddMethod,
                },
                scopedSlots: _vm._u([
                  {
                    key: "assist",
                    fn: function ({ data }) {
                      return [
                        _c(
                          "span",
                          { staticClass: "custom-assist" },
                          _vm._l(
                            _vm.column.assistSelect,
                            function (item, index) {
                              return _c(
                                "span",
                                { key: index, staticClass: "assist-block" },
                                [
                                  data[item.field]
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(item.name) +
                                            ": " +
                                            _vm._s(data[item.field])
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    },
                  },
                  {
                    key: "tags",
                    fn: function ({ showSelected, info }) {
                      return [
                        _c(
                          "div",
                          { key: 1 },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  width: "300",
                                  trigger: "hover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "sculpture-list",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._l(
                                      _vm.column.showValue.slice(0, 3),
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "sculpture-style",
                                          },
                                          [
                                            index < 3
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      [...item.name][0] || ""
                                                    )
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm.column.showValue.length >= 3
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "sculpture-style-other",
                                          },
                                          [
                                            _vm._v(
                                              " +" +
                                                _vm._s(
                                                  _vm.column.showValue.length -
                                                    3
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                                _vm._l(showSelected, function (item) {
                                  return _c(
                                    "el-tag",
                                    {
                                      key: item.value,
                                      staticClass: "en-select-tag",
                                      attrs: {
                                        closable: !info.selectDisabled,
                                        size: info.collapseTagSize,
                                        hit: item.hitState,
                                        type: "info",
                                        "disable-transitions": "",
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.deleteTag($event, item)
                                        },
                                      },
                                    },
                                    [
                                      info.thumTag
                                        ? [
                                            item.node && item.node.thum
                                              ? _c("img", {
                                                  staticClass:
                                                    "en-select__tags-thum en-select__tags-thum-image",
                                                  attrs: {
                                                    src: item.node.thum,
                                                  },
                                                })
                                              : _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "en-select__tags-thum en-select__tags-thum-text",
                                                    style: {
                                                      "background-color":
                                                        item.node &&
                                                        item.node
                                                          .thumBackgroundColor
                                                          ? item.node
                                                              .thumBackgroundColor
                                                          : "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.node &&
                                                            item.node.thumText
                                                            ? item.node.thumText
                                                            : item.currentLabel.substr(
                                                                0,
                                                                1
                                                              )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "el-select__tags-text",
                                          style: {
                                            color:
                                              item.node && item.node.color
                                                ? item.node.color
                                                : "",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.currentLabel))]
                                      ),
                                    ],
                                    2
                                  )
                                }),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.column.showValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.column, "showValue", $$v)
                  },
                  expression: "column.showValue",
                },
              }),
              _vm.hasRightBtn
                ? _c("RightAddition", {
                    attrs: {
                      column: _vm.column,
                      "row-index": _vm.rowIndex,
                      "business-data": _vm.businessData,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
      _c("pop-auto-assign", {
        ref: "autoAssignMod",
        attrs: { column: _vm.column, loadDataFn: _vm.treeLoad },
        on: { handleBaseChoose: _vm.selectSuccess },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }