<!--
 * @Author: Fenngding
 * @Date: 2019-09-19 16:44:29
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-03-04 11:43:20
 * @Email: fenngding@live.com
 * @Description: 用于存放微服务的全屏容器
 -->
 <template>
  <div class="micro-menu-page-container" :class="{
    [themeInfo.themeColor + '-theme']: themeInfo.navStyle !== 3,
    [themeInfo.themeColor + '-theme-top-one']: themeInfo.navStyle === 3 && themeInfo.RecommendedStyles === 5,
    [themeInfo.themeColor + '-theme-top-two']: themeInfo.navStyle === 3 && themeInfo.RecommendedStyles === 6,
    [themeInfo.themeColor + '-theme-top-three']: themeInfo.navStyle === 3 && themeInfo.RecommendedStyles === 7,
    'custom-nav-bg': themeInfo.openCustomColor
  }">
    <div class="home-router">
      <top-nav-menu v-if="pageLayout <= 1" ></top-nav-menu>
      <top-menu v-if="pageLayout === 0 && themeInfo.navStyle == 3" :style="navStyle" :activeId="themeInfo.themeStyle"
        v-on:onChildData='onChildData' />
      <div style='float:left;color:#fff;height:100%;width:218px;'
        v-if='pageLayout === 0 && themeInfo.navStyle == 3 && leftChildList.length > 0'>
        <el-menu :style="[navBorderStyle, navStyle]" class='menu2' text-color="#a9b5c6" active-text-color="#fff"
          :unique-opened="true" collapse-transition @open="handleOpen" @close="handleClose">

          <template v-for="item in leftChildList" class="en-submenu">

            <el-menu-item :index="item.index" :activeId="activeId" @click.native="clickMenuItem(item)">
              <div>
                <en-icon class="en-icon"  :name="item.icon" size="16" />
                <span slot="title" class="en-icon-title">{{ item.name }}</span>
              </div>
            </el-menu-item>

          </template>
        </el-menu>
      </div>
      <div class="home-content" :style="{ height: containerHeight }">
        <div class="menu-content" :style="navStyle">
          <left-nav-menu v-if="pageLayout === 0 && themeInfo.navStyle !== 3" :navStyles="themeInfo.navStyle"
            :activeId="themeInfo.themeStyle" @menuClick="handleMenuClick" />
        </div>
        <div class="right-area" :style="{ width: containerWidth }">
          <div class="router-show-area" :style="{ ...containerStyle, padding: pageLayout === 2 ? '0' : '8px' }">
            <div class="loading-container" :style="{ borderRadius: pageLayout === 2 ? '0' : '5px' }">
              <!-- v-if主题设置 打开 -->
              <el-tabs tab-size="8" v-if="themeInfo.openIndexTab && themeInfo.openRouterTab &&editableTabs.length>0 && !isHide" v-model="editableTabsValue" type="card" closable
                @tab-click="handleTabClick" @tab-remove="removeTab">
                <!-- 点击主菜单一项 menuItems增加一项 -->
                <el-tab-pane v-for="item in editableTabs" :key="item.name" :name="item.name" :title="item.title">
                  <span slot="label" class="item-tab">

                    <en-icon v-if="item.color.length" :name="item.icon" style="margin-right:2px" size="15px" :style="{ color: item.color }"></en-icon>
                    <div v-else class="icon-box" :style="{ ...item.color }">
                      <en-icon :style="{ color: '#fff' }" :name="item.icon" style="margin-right:2px" size="15px"></en-icon>
                    </div>
                    {{ item.title.length > 10 ? item.title.slice(0,9)+'...' :  item.title }}</span>
                </el-tab-pane>
              </el-tabs>

              <keep-alive>
                <router-view :key="$route.fullPath" v-if="$route.meta.keepAlive" class="full-page" @updateTabs="updateTabs"></router-view>
              </keep-alive>
              <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath" class="full-page"
                @updateTabs="updateTabs"></router-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import timewait from "timewait";
import {
  createNamespacedHelpers, mapState
} from "vuex";
import leftNavMenu from "@/components/left-nav-menu";
import topNavMenu from "@/components/top-nav-menu";
import topMenu from "@/components/top-menu";
import { menuRouterData, menuClassifyTypeEnum, menuDataTypeEnum } from "@/api/homeMenu/handleMenu";
import { enChart } from "@/api/businessChart";

const { mapState: mapStateTheme } = createNamespacedHelpers("main");
export default {
  name: "MicroMenuPage",
  components: { leftNavMenu, topNavMenu, topMenu },
  props: {
    loading: Boolean, // 微服务的加载动画
    content: [String, Boolean], // 微服务的加载内容
    showMicro: Boolean, // 是否显示微服务模板
    pageLayout: Number
  },
  data() {
    return {
      editableTabsValue: "",
      baseNum: 0,
      isHide: false,
      editableTabs: [],
      loadingConfig: {
        type: "loading",
        subTitle: "微模块加载中,请稍等..."
      },
      loadingFlag: "",
      leftChildList: [],
      activeId: 1
      // BackgroundImageStyleTwo: requise("@/assets/images/BackgroundImageStyleTwo.png")

    };
  },
  computed: {
    ...mapState(["isChangeOrg"]),
    ...mapStateTheme(["themeInfo"]),
    containerStyle() {
      // const { openCustomColor, bgColor } = this.themeInfo;
      // if (openCustomColor) {
      //   return {
      //     background: bgColor
      //   };
      // }
      //   return {
      //     background: bgColor
      //   };
      const {
      openCustomColor, navColor, bgColorRadio, bgColor
      } = this.themeInfo;
      console.log(this.themeInfo, "ssss");
      const resStyle = {
          background: "",
          backgroundSize: ""
      };
      if (openCustomColor) {
        console.log("自定义背景颜色left", bgColorRadio, bgColor);
        // eslint-disable-next-line eqeqeq
        if (bgColorRadio == 1) {
          console.log("进入这里了么111");
          resStyle.background = bgColor;
          // eslint-disable-next-line eqeqeq
        } else if (bgColorRadio == 2) {
            resStyle["background-image"] = "url('https://img2.baidu.com/it/u=2334136981,437917035&fm=253&fmt=auto&app=138&f=JPEG?w=547&h=342')";
            // resStyle.background: "url('https://img2.baidu.com/it/u=2334136981,437917035&fm=253&fmt=auto&app=138&f=JPEG?w=547&h=342')";
            resStyle["background-repeat"] = "no-repeat";
          // resStyle.background-repeat = '';
            // "cover";
            resStyle["background-size"] = "100% 100%";
        }
        // resStyle.background = navColor;
      } else {
          console.log(1234, bgColor);
          resStyle.background = bgColor;
      }

      // console.log("resStyle=========", resStyle);
      return resStyle;
    },
    navStyle() {
      const { openCustomColor, navColor, navColorRadio } = this.themeInfo;
      const resStyle = {
          background: "",
          backgroundSize: ""
      };
      // console.log(navColorRadio, "navColorRadio");
      if (openCustomColor) {
        // console.log("navColorRadio", navColorRadio, navColor);
        // eslint-disable-next-line eqeqeq
        if (navColorRadio == 1) {
          resStyle.background = navColor;
          // eslint-disable-next-line eqeqeq
        } else if (navColorRadio == 2) {
          resStyle["background-image"] = "url('https://img2.baidu.com/it/u=2334136981,437917035&fm=253&fmt=auto&app=138&f=JPEG?w=547&h=342')";
            // resStyle.background: "url('https://img2.baidu.com/it/u=2334136981,437917035&fm=253&fmt=auto&app=138&f=JPEG?w=547&h=342')";
            resStyle["background-repeat"] = "no-repeat";
          // resStyle.background-repeat = '';
            // "cover";
            resStyle["background-size"] = "100% 100%";
        } else {
          resStyle.background = "unset";
        }
        // resStyle.background = navColor;
      }
      // https://img2.baidu.com/it/u=2334136981,437917035&fm=253&fmt=auto&app=138&f=JPEG?w=547&h=342
      // 菜单栏样式
      // if (this.themeInfo.themeStyle === 3 && this.themeInfo.navStyle === 1) {
      //   resStyle.borderRadius = "0px 30px 0px 0px";
      // } if (this.themeInfo.themeStyle === 3 && this.themeInfo.navStyle === 2) {
      //   resStyle.borderRadius = "0px 30px 0px 0px";
      // } if (this.themeInfo.themeStyle === 3 && this.themeInfo.navStyle === 3) {
      //   resStyle.borderRadius = "25px";
      //   resStyle.boxShadow = "0px 5px 9px 1px rgba(158,186,219,0.3100)";
      // }
      // console.log("resStyle=========", resStyle);
      return resStyle;
    },
    containerHeight() {
      // 0 显示菜单和导航; 1显示导航; 2显示全屏
      return this.pageLayout === 2 ? "100%" : `calc(100% - ${this.themeInfo.navStyle === 3 ? "112" : "61"}px)`;
    },
    containerWidth() {
      return this.pageLayout ? "100%" : "calc(100% - 188px)";
    },
    navBorderStyle() {
      const { openCustomColor, navColor } = this.themeInfo;
      if (openCustomColor) {
        return {
          borderRightColor: navColor
        };
      }
      return {};
    }
  },
  watch: {
    $route: {
    handler(val, oldVal) {
      console.log(val, oldVal);

      if (sessionStorage.getItem("tableTabs")) {
      this.editableTabs = JSON.parse(sessionStorage.getItem("tableTabs"));
      this.editableTabs.forEach((it) => {
        console.log(this.$route.path, it);
        if (it.apath === this.$route.path) {
          this.editableTabsValue = it.name;
        } else if (
          this.$route.query.templateName
          && this.$route.query.templateName === it.title
        ) {
          this.editableTabsValue = it.name;
        }
      });
    }
    },
    // 深度观察监听
    deep: true
  },
    isChangeOrg(val) {
      this.editableTabs = [];
    },
    // pageLayout: {
    //   handler(val) { // 根据微服务的加载状态切换动画
    //     console.log("newwwwwwwww", val);
    //   }
    // },
    loading: {
      handler(val) { // 根据微服务的加载状态切换动画
        // console.log("加载状态：", val);
        this.loadingConfig.type = val ? "loading" : null;
        if (val) {
          this.loadingFlag = new Date().getTime();
          this.countDown(this.loadingFlag);
        }
      }
    }
  },
  methods: {
    // 点击主菜单
    handleMenuClick(menu) {
      setTimeout(() => {
        console.log("handleMenuClick", menu);
          // 回到首页or关闭首页页签时 清除页签组
          if (!this.themeInfo.openIndexTab) {
            this.editableTabs = [];
            this.editableTabsValue = "";
            this.baseNum = 0;
            return;
          }

          // 已有页签不重复添加,仅 active状态页签更新
          let existFlag = false;
          this.editableTabs.forEach((item) => {
            if (item.name === menu.name) {
              existFlag = true;
              this.editableTabsValue = menu.name;
            }
          });

          if (existFlag) {
            return;
          }
           // 路径处理
            this.baseNum++;
            // const pathArr = this.$route.fullPath?.split("?");
            // const queryArr = pathArr[1]?.indexOf("&") > -1 ? pathArr[1].split("&") : [pathArr[1]];
            const qobj = this.$route.query;
            // queryArr.forEach((item) => {
            //   if (item) {
            //   const kv = item?.split("=");
            //   const k = kv[0];
            //   const v = kv[1];
            //   qobj[k] = v;
            //   }
            // });
            // 首页默认排第一
            if (menu.name === "首页") {
                this.editableTabs.unshift({
                icon: menu.customIcon,
                color: menu.customColor,
                addNo: String(this.baseNum),
                title: menu.name,
                name: menu.name,
                apath: JSON.parse(JSON.stringify(this.$route.path)),
                fpath: JSON.parse(JSON.stringify(this.$route.fullPath)),
                query: JSON.parse(JSON.stringify(qobj))
              });
            } else {
                this.editableTabs.push({
                icon: menu.customIcon,
                color: menu.customColor,
                addNo: String(this.baseNum),
                title: menu.name,
                name: menu.name,
                apath: JSON.parse(JSON.stringify(this.$route.path)),
                fpath: JSON.parse(JSON.stringify(this.$route.fullPath)),
                query: JSON.parse(JSON.stringify(qobj))
              });
            }
            this.editableTabsValue = menu.name;
            sessionStorage.setItem("tableTabs", JSON.stringify(this.editableTabs));
        }, 100);
    },
    // 点击页签项
    handleTabClick(tab, event) {
      const cliname = tab.name;
      const index = Number(tab.index);
      const routerToInfo = this.editableTabs[index];
       if (routerToInfo.path?.indexOf("businessModel/detail") > -1) {
        this.$router.push({
          path: routerToInfo.path
        });
        return;
      }
      if (routerToInfo.dataType) {
        this.$router.push({
              path: routerToInfo.path,
              query: routerToInfo.query
          });
        return;
      }
      if (routerToInfo.apath.indexOf("boardList") > -1) {
        this.$router.push({
          name: "boardList",
          query: routerToInfo.query
        });
      } else if (routerToInfo.apath.indexOf("toModelChartUser") > -1) {
        this.$router.push({
          path: "/toModelChartUser",
          query: routerToInfo.query
        });
      } else {
        // 如有更多特殊场景 需补充
        this.$router.push({
          path: routerToInfo.apath,
          query: routerToInfo.query
        });
      }
      // try {
      //   this.editableTabs.forEach((item) => {
      //   if (item.title === cliname) {
      //     if (item.apath.indexOf("boardList") > -1) {
      //       this.$router.push({
      //         name: "boardList",
      //         query: item.query
      //       });
      //       throw new Error("boardList");
      //     } if (item.apath.indexOf("toModelChartUser") > -1) {
      //       this.$router.push({
      //         path: "/toModelChartUser",
      //         query: item.query
      //       });
      //       throw new Error("toModelChartUser");
      //     } else {
      //       // 如有更多特殊场景 需补充
      //       this.$router.push({
      //         path: item.apath,
      //         query: item.query
      //       });
      //       throw new Error("ather");
      //     }
      //   } if (item.dataType) {
      //     this.$router.push({
      //         path: item.path,
      //         query: item.query
      //       });
      //       throw new Error("modelist");
      //   }
      // });
      // } catch (error) {
      //   console.log(error);
      // }
    },
    // 删除页签项
    removeTab(targetName) {
      // targetName el-tab-pane~name:item.name
      // this.editableTabsValue active~el-tab-pane~name
      const tabs = this.editableTabs;
      const activeName = this.editableTabsValue;
      // 删除当前页签 前移一个
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              this.editableTabsValue = nextTab.name;
              if (nextTab.path?.indexOf("businessModel/detail") > -1) {
                this.$router.push({
                  path: nextTab.path
                });
                return;
              }
              // main area 显示后一个tab的path
              if (nextTab?.apath?.indexOf("boardList") > -1) {
                this.$router.push({
                  name: "boardList",
                  query: nextTab.query
                });
              } else if (nextTab?.apath?.indexOf("toModelChartUser") > -1) {
                this.$router.push({
                  path: "/toModelChartUser",
                  query: nextTab.query
                });
              } else {
                // 如有更多特殊场景 需补充
                this.$router.push({
                  path: nextTab.apath || nextTab.path,
                  query: nextTab.query
                });
              }
            }
          }
        });
      }
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);

      // 删到最后一个，返回首页
      if (this.editableTabs.length < 1) {
        this.$router.push({
          path: "/home/index"
        });
      }
      sessionStorage.setItem("tableTabs", JSON.stringify(this.editableTabs));
    },
    // 点击页签内业务建模子模块
    updateTabs(newTabs) {
      console.log("update");
       // 已有页签不重复添加,仅 active状态页签更新
       let existFlag = false;
      this.editableTabs.forEach((item, index) => {
        if (item.title === newTabs.title) {
          existFlag = true;
          this.editableTabsValue = newTabs.title;
        }
      });

      if (existFlag) {
        return;
      }
      // 增加页签项
       // active页签 更新

      this.baseNum++;
      this.editableTabs.push({
        title: newTabs.title,
        icon: newTabs.icon,
        color: newTabs.color,
        addNo: String(this.baseNum),
        name: newTabs.title,
        path: newTabs.path,
        query: JSON.parse(JSON.stringify(newTabs.query)),
        dataType: newTabs.dataType || ""
      });
      this.editableTabsValue = newTabs.title;
sessionStorage.setItem("tableTabs", JSON.stringify(this.editableTabs));
    },
    /**
     * 点击菜单处理
     */
    async clickMenuItem(item) {
      // menuFixedTypeEnum.businessClassify
      // console.log(item, "333");
      const itemData = item.data || item;
      if (itemData?.type === 3 && item.children) {
        if (item.data.refData.hasBoard === "0") {
          const res = await enChart.queryUserConfig({ configKey: `picList-${item.data.id}` });
          if (!res) {
            this.$router.push({ path: "/toModelChartUser", query: { templateId: item.data.id, type: 1 } });
          } else if (res.value === "1") {
            // 进图表
            this.$router.push({ path: "/toModelChartUser", query: { templateId: item.data.id, type: 1 } });
          } else {
            // 2 进列表
            this.$router.push({
              name: "boardList",
              query: { id: item.data.id }
            });
          }
        } else {
          this.$router.push({
            name: "boardList",
            query: { id: item.data.id }
          });
        }
      }
      if (!item.children) {
        // if (item.data.code === menuFixedTypeEnum.mySalarySheet) {
        //   this.payRollPath = menuRouterData(item.data);
        //   this.$refs.promptIndex.handlePayRoll();
        // } else {
        const { path, query } = menuRouterData(itemData || item);

        if (itemData.icon === "2_18" || itemData.code === "073") {
          // 法律知识库and邮箱
          const routeData = this.$router.resolve({
            path
          });
          window.open(routeData.href, "_blank");
        } else if (itemData.code === "075") {
          // 外部链接 1-在新窗口显示，2-在当前窗口显示
          if (itemData.outType === 1) {
            window.open(itemData.outUrl);
          } else {
            window.location.href = itemData.outUrl;
          }
        } else if (path.length > 0) {
          this.$router.push({
            path,
            query
          });
        }
        // }
      }
    },
    handleOpen() {

    },
    handleClose() {

    },
    onChildData(data) {
      console.log(data, "onChildData");
      this.leftChildList = data;
    },
    async countDown(flag) {
      await timewait(30000);
      if (this.loading && flag === this.loadingFlag) {
        this.loadingConfig.type = "BrokenNet";
        this.loadingConfig.subTitle = "网络好慢哟...";
      }
    }
  },
  created() {
    console.log("向left menu 子组件传递的参数", this.themeInfo, this.themeInfo.themeStyle);
    if (sessionStorage.getItem("tableTabs")) {
      this.editableTabs = JSON.parse(sessionStorage.getItem("tableTabs"));
      this.editableTabs.forEach((it) => {
        if (it.apath === this.$route.path) {
          this.editableTabsValue = it.name;
        } else if (this.$route.query.templateName && this.$route.query.templateName === it.title) {
          this.editableTabsValue = it.name;
        }
      });
    }
    if (this.$route.query.isSF === "1") {
      this.isHide = true;
    }
  }

};
</script>
<style scoped lang="scss">
.el-tabs__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-tabs__item-label {
  max-width: 8em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.el-tabs--top {
  background: #F3F7FF;
}

/deep/ .el-tabs--card>.el-tabs__header {
   border: none!important;
}
.item-tab{
  display: flex;
  align-items: center;
  .en-icon{
    margin-right: 5px!important;
  }
}
/deep/.el-tabs--card>.el-tabs__header .el-tabs__nav{
  display: flex;
  border:none;
}
/deep/.el-tabs--card>.el-tabs__header .el-tabs__item {
  border: none;
  width: 198px;
  height: 42px;
  background: #F3F7FF;
  // border-right: 1px solid #A9B5C6;
  display: flex;
    justify-content: space-between;
    align-items: center;
}
/deep/ .el-tabs__item{
  position: relative;
}
/deep/ .el-tabs__item::after{
  position: absolute;
  content: "";
  width: 1px;
  height: 15px;
  background: #A9B5C6;
  opacity: 0.15;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
/deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    background: #fff;
    min-width: 198px;
    height: 42px;
    box-shadow: 0px 4px 6px 0px rgba(25,36,48,0.05);
    border-radius: 14px 8px 0px 0px;
    border: none;
}
/deep/ .el-tabs__item .el-icon-close{
  line-height: 14px!important;
  font-size:14px !important;
}
.micro-menu-page-container {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .container {
    height: 100%;
    width: 100%;
  }

  .home-router {
    height: 100%;
    width: 100%;

    .menu-content {
      height: 100%;
      flex-grow: 0;
      vertical-align: top;
      background-color: #232c3d;
      //overflow: hidden;
    }

    .home-content {
      height: calc(100% - 61px);
      display: flex;

      .right-area {
        width: calc(100% - 188px);
        flex-grow: 1;
        height: 100%;

        .router-show-area {
          height: 100%;
          width: 100%;
          padding: 8px;
          background-color: #cbc9ca;
          overflow: hidden;

          .loading-container {
            overflow: hidden;
            border-radius: 5px;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction:column;
            .full-page {
              position: relative;
              // width: calc(100% + 8px);
              flex:1;
              height:auto;
              overflow: auto;
            }

            .container {
              background: white;
              overflow: hidden;
              height: 100%;
              width: 100%;
              /*/deep/ {*/
              /*  & > div {*/
              /*    position: relative;*/
              /*    width: 100%;*/
              /*    height: 100%;*/
              /*  }*/
              /*}*/
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.h18 {
  height: 18px !important;
}

.textAlign {
  text-align: center !important;
}

.h40 {
  height: 40px !important;
}

.ml-10 {
  margin-left: -10px !important;
}

.font12 {
  font-size: 12px !important;
}

/deep/ .el-submenu .el-menu-item {
  // width: 188px;
  min-width: 80px;
  line-height: 48px;
  height: 48px;

  &.is-active {

    // background-color: rgba(117, 131, 154, 0.2) !important;
    &.is-opened {
      background: #454f64 !important;
    }
  }
}

/deep/ .el-menu--vertical {
  top: 0 !important;
  background: #232c3d !important;
}

/deep/ .el-icon-arrow-right.icon+.en-icon-title+.el-submenu__icon-arrow.el-icon-arrow-right {
  display: none;
}

.en-icon {
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
}

.en-icon-title {
  display: inline-block;
  vertical-align: middle !important;
}

// 长菜单
.menu {
  // background: #232c3d;
  height: calc(100% - 80px);
  font-weight: 400;
  user-select: none;
  width: 188px;
  display: inline-block;
  text-align: left;
  border-right: solid 1px #232c3d;
  position: relative;
  transition: all 0.1s linear;
  overflow: auto;

  &.collapse {
    width: 80px;
    transition: all 0.1s linear;
  }

  &::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
  /deep/ .el-submenu {
    background: #232c3d;

    .el-submenu__title {
      &:hover {
        &.is-1 {
          border-left: 2px solid #3e90fe;
        }

        &.is-2 {
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          margin: 4px 0;
        }

        &.is-3 {
          // border-bottom-left-radius: 4px;
          // border-bottom-right-radius: 4px;
        }

        &.is-4 {
          height: 40px;
          line-height: 40px;
          width: calc(100% - 10px);
          border-radius: 2px;
        }
      }
    }

    &.is-opened {
      background: #2a3345;

      .el-menu-item {
        background: #2a3345;

        &.is-active {
          background: #394356;
        }
      }
    }
  }
  /deep/ .el-menu-item {
    background: #232c3d;

    // &.is-active {
    //   background: #394356;
    //   border-left: 2px solid #3e90fe;
    // }
    &.is-active-1 {
      background: #fff !important;
      border-left: 2px solid #3e90fe;
    }

    &.is-active-2 {
      height: 40px;
      line-height: 40px;
      background: #fff !important;
      border-radius: 20px;
    }

    &.is-active-3 {
      margin:0 1px;
      // background: #fff !important;
      // border-bottom-left-radius: 4px;
      // border-bottom-right-radius: 4px;
    }

    &.is-active-4 {
      // background: #fff !important;
      height: 40px;
      line-height: 40px;
      width: calc(100% - 10px);
      border-radius: 2px;
    }

    &.is-active-1-two {
      background: #fff !important;
      border-left: 2px solid #3e90fe;
    }

    &.is-active-2-two {
      height: 40px;
      line-height: 27px;
      background: #fff !important;
      border-radius: 20px;
    }

    &.is-active-3-two {
      background: #fff !important;
      // border-bottom-left-radius: 4px;
      // border-bottom-right-radius: 4px;
    }

    &.is-active-4-two {
      background: #fff !important;
      height: 40px;
      line-height: 40px;
      width: calc(100% - 10px);
      border-radius: 2px;
    }

    &:hover {
      &.is-1 {
        border-left: 2px solid #3e90fe;
      }

      &.is-2 {
        height: 40px;
        line-height: 40px;
        border-radius: 20px;
        margin: 4px 0;
      }

      &.is-3 {
        // border-bottom-left-radius: 4px;
        // border-bottom-right-radius: 4px;
      }

      &.is-4 {
        height: 40px;
        line-height: 40px;
        width: calc(100% - 10px);
        border-radius: 2px;
      }

      &.is-1-two {
        border-left: 2px solid #3e90fe;
      }

      &.is-2-two {
        height: 40px;
        line-height: 27px;
        border-radius: 20px;
        margin: 4px 0;
      }

      &.is-3-two {
        // border-bottom-left-radius: 4px;
        // border-bottom-right-radius: 4px;
      }

      &.is-4-two {
        height: 40px;
        line-height: 40px;
        width: calc(100% - 10px);
        border-radius: 2px;
      }
    }
  }
}
/deep/ .el-tabs__item::before{
  position: absolute;
    content: "";
    width: 1px;
    height: 15px;
    background: #A9B5C6;
    opacity: 0.15;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
/deep/ .el-tabs__nav .is-active::before{
  content:none
}
/deep/ .el-tabs__item::after  {
  content:none
}
/deep/ .el-tabs__item:first-of-type::before{
  content: none;
}
/deep/ .is-active+.el-tabs__item::before{
  content: none;
}
.icon-box{
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 5px;
  .en-icon{
    margin: 0!important;
    width: 12px!important;
    height: 12px!important;
  }
}
</style>
