<!--
 * @Descripttion: 数据导入(模板导入)
 * @Author:
 * @Date: 2021-10-14
 * @LastEditors:
 * @LastEditTime:
-->
<template>
  <el-dialog :visible="showDialog" title="数据导入" en-body-scroll @close="showDialog = false">
    <div class="import-add">
      <div class="upload-wrap" v-if="!uploadFile">
        <div>
          <img src="@/assets/images/upload-img.png" alt="">
          <p class="tips">仅支持扩展名xls、xlsx</p>
        </div>
        <el-upload
          ref="upload"
          :action="uploadUrl+'/mdDataDetailImport'"
          :data='uploadParams'
          class="upload_lay"
          name="importFile"
          accept=".xlsx,.xls"
          :show-file-list='false'
          :headers="header"
          :on-success="handleSuccess"
          :on-error='handleError'
          :on-progress='handleProgress'
        >
          <en-button size="small" class='btn-item'>
            立即上传模板
          </en-button>
        </el-upload>
      </div>
      <div class='item-content upload-process' v-if="uploadFile">
          <en-upload-progress
            :iconColor='suffix(uploadFile.name).iconColor'
            :iconName='suffix(uploadFile.name).iconName'
            @viewErrorLog="dowmImportResult"
            :fileName='uploadFile.name'
            :progress-status="uploadStatus" :percent='uploadFile.percentage'></en-upload-progress>
            <i class='el-icon-error delete-upload' @click="clearFile"></i>
        </div>
    </div>
    <div slot="footer">
      <div>
        <en-button style="margin-left: 10px;" :disabled="!dataList.length" type="green" @click="confirmAdd(1)">
          <el-tooltip effect="dark" content="此次选择的数据累加在原有数据后方" placement="bottom-start">
            <span>依次新增</span>
          </el-tooltip>
        </en-button>
        <en-button @click="confirmAdd(2)" :disabled="!dataList.length">
          <el-tooltip effect="dark" content="原有数据被删除，此次选择的数据将替代原有数据" placement="bottom-start">
            <span>覆盖新增</span>
          </el-tooltip>
        </en-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { businessService } from "@/api/businessModel";
import folderComm from "@/mixins/myFolder/folderComm.js";
import { enUrl } from "../../../../../../../baseUrl";

export default {
  name: "ImportAddByTemp",
  componentName: "ImportAddByTemp",
  props: {
    column: Object
  },
  mixins: [folderComm],
  data() {
    return {
      showDialog: false,
      importError: false,
      uploadFile: null, // 当前上传的文件
      uploadStatus: "", // 当前上传状态
      importTemplateId: "",
      dataList: []
    };
  },
  computed: {
    ...mapGetters(["urlParam", "tenantId", "appSessionId"]),
    uploadUrl() {
      return enUrl;
    },
  header() {
      return {
        Authorization: sessionStorage.getItem("jwtToken")
      };
    },
    uploadParams() {
      return {
        channelType: "PC",
        channelcode: "PC",
        // appKey: this.urlParam.appKey,
        // token: this.urlParam.token,
        // ts: this.urlParam.ts,
        appSessionId: this.appSessionId,
        tenantId: this.tenantId,
        templateId: this.column.templateId,
        version: this.column.version,
        field: this.column.field,
        importTemplateId: this.importTemplateId
      };
    }
  },
  methods: {
    clearFile() {
      this.uploadFile = null;
      this.dataList = [];
    },
    confirmAdd(type) {
      this.$emit("importSuccess", type, this.dataList);
      this.showDialog = false;
      this.clearFile();
    },
    openDialog(id) {
      this.importTemplateId = id;
      this.showDialog = true;
    },
    async dowmImportResult() {
      await businessService.exportImportExcelResult({ templateId: this.column.templateId, tenantId: this.tenantId });
    },
    handleSuccess(data, file, fileList) {
      console.log(data, file, fileList);
      if (data && data.msgType === "N") {
        if (data.rspData) {
          if (data.rspData.rspData?.error) {
            this.importError = true;
            this.uploadStatus = "error";
          } else {
            this.dataList = data.rspData?.dataList || [];
          }
        } else {
          this.$message.info("无数据");
          this.uploadFile = null;
        }
      } else {
        this.$message.error(data.rspMsg);
        this.uploadFile = null;
      }
    },
    handleProgress(event, file, fileList) {
      console.log(event, file, fileList);
      this.uploadFile = file;
      this.uploadStatus = "loading";
    },
    handleError() {
      this.uploadStatus = "error";
    }
  }
};
</script>

<style lang="scss" scoped>
.import-add {
  // padding: 20px 0;
  .upload-wrap {
    width: 100%;
    height: 410px;
    border: 1px dashed #E8E8E8;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .tips {
      font-size: 12px;
      color: #AEAEAE;
    }
  }
  .upload-process{
    position: relative;
    display: inline-block;
    .delete-upload {
      position: absolute;
      font-size: 16px;
      right: -8px;
      top: -8px;
      color: red;
      cursor: pointer;
    }
  }
  .upload-process {
    .upload-container {
      width: 690px;
    }
  }
}
</style>
