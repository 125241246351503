/*
 * @Description: select 选择控件
 * @Author: 梁平贤
 * @LastEditors: Please set LastEditors
 * @Date: 2019-09-11 16:23:21
 * @LastEditTime: 2024-03-04 13:20:33
 */
import _ from "lodash";
import { getObjectType } from "@/tools/util.js";
import { selectService } from "@/api";
import BaseColumn from "../base/BaseColumn";
import { AssignmentOptions } from "../../BusinessCommonHeader.js";
/* eslint-disable */
export default class SelectColumn extends BaseColumn {

  // 用于保存  selectValue
  columnValue;

  // 组件中使用
  showValue;

  defaultProps = {
    label: "name",
    value: "id",
    isLeaf: "leaf"
  };

  // 组件返回值接口
  returnOtherProps;

  constructor(fields, businessData) {
    super(fields);
    this.otherLabelValue(); // 数据格式不为id-name特殊处理
    this.businessData = businessData;
  }
  // 是否勾选父项
  get checkParent() {
    return this.choiceRule === 1;
  }

  get columnIsCascade() {
    return this.isCascade === 0 && this.prefixField.length > 0;
  }

  get paramsForSubmit() {
    const value = this.selectFormatValue;
    if (value?.length === 0) return null;
    if (!this.checkCouldSubmit) return null;
    // 部分单选多选  label value 不是常规id-name
    const submitValue = value.map((item) => {
      const res = {
        id: item[this.defaultProps?.value] || item.id,
        name: item[this.defaultProps?.label] || item.name,
        fieldType: this.originalFieldType || this.fieldType // 控件类型
      };
      if (this.returnOtherProps && this.returnOtherProps.length) {
        this.returnOtherProps.forEach((key) => {
          res[key] = item[key];
        });
      }
      return res;
    });
    return {
      [this.field]: submitValue
    };
  }

  get selectFormatValue() {
    return this.isMultiSelect ? (this.columnValue || []) : ((this.columnValue && JSON.stringify(this.columnValue) !== "{}") ? [this.columnValue] : []);
  }

  // 暂时先这样写, 字段的值转换文本展示,后续有需要再改
  get textValue() {
    if (this.showFlag && this.showFlag.length > 0) {
      return this.showFlag;
    }
    return this.selectFormatValue?.map((item) => item.name).join("");
  }

  get checkCouldSubmit() {
    // 必填 且为空 返回err
    if (this.required && this.selectFormatValue?.length === 0) {
      return new Error(`请选择${ this.name}`);
    }
    // 否则 true
    return true;
  }

  get thumTag() {
    return this.fieldType === 15;
  }

  otherLabelValue() {
    // 城市
    if (this.field === "city") {
      this.defaultProps.value = "code";
    // 开户支行
    } else if (this.field === "bankCode") {
      this.defaultProps.label = "bankName";
      this.defaultProps.value = "bankCode";
    } else if (this.field === "leaveType") {
      this.defaultProps.label = "text";
    }
  }

  isJSON(str) {
    if (typeof str === "string") {
      try {
        const obj = JSON.parse(str);
        if (typeof obj === "object" && obj) {
          return true;
        }
        return false;
      } catch (e) {
        return false;
      }
    }
  }

  // 解析数据
  parseColumnDefaults(value) {
    // 部分数据 不是 string 如申请人部门
    if (typeof value === "string" && this.isJSON(value)) {
      return JSON.parse(value);
    } if (typeof value === "object") {
      return value;
    }
    return '';
  }

  formatLabelValue(val) {
    if (!this.defaultProps || !val || JSON.stringify(val) === "{}") return val;
    if (getObjectType(val) === "array") {
      val = val.map((item) => Object.assign(item, {
        [this.defaultProps.label]: item.name || item[this.defaultProps.label],
        [this.defaultProps.value]: item.id || item[this.defaultProps.value]
      }));
    } else {
      Object.assign(val, {
        [this.defaultProps.label]: val.name || val[this.defaultProps.label],
        [this.defaultProps.value]: val.id || val[this.defaultProps.value]
      });
    }
    return val;
  }

  // 更新值
  updateValueWithOptions(value, opts) {
    typeof value === "string" && (value = this.parseColumnDefaults(value)); // 赋值为字符串时解析数据
    if (!this.canAssignmentWithValueAndOpts(value, opts)) return;
    // 每次赋值 检测defaultProps 改变id-name为 defaultProps中对应label value
    value = this.formatLabelValue(value);
    // 更新status 为 default 则 解析数据

    // 触发单选字段 的选项控制
    if (getObjectType(value) === "array") {
      this.initTheFieldControl(value[0]);
    } else if (getObjectType(value) === "object") {
      this.initTheFieldControl(value);
    } else {
      this.initTheFieldControl({});
    }

    // 更新 columnValue
    this.updateColumnValueWithOptions(value);
    super.updateValueWithOptions(value, opts);

    if (opts === AssignmentOptions.default) {
      // 保留
      // 默认值也要触发自动赋值
      const { dataSource, autoEvaluation } = this;
      // const {openType} = rootNode;
      // 新增 且 基础数据 且 配置了自动赋值
      // 还需要判断是明细区或者子集区的 新增行
      if ([1, undefined, ""].includes(dataSource) && autoEvaluation.length) {
        this.setAutoAssignment();
      }

    }


  }

  // 根据赋值类型来判断能否赋值
  canAssignmentWithValueAndOpts(value) {
    // 根据配置 不允许修改则不能修改 组件选择位置控制
    // 值是一样的不让赋值 不重新赋值
    if (_.isEqual(value, this.columnValue)) {
      return false;
    }
    return true;
  }

  // 更新真实存储的值
  updateColumnValueWithOptions(val) {
    if (this.isMultiSelect) {
      val = Array.isArray(val) ? val : [val];
    }
    this.columnValue = this.isMultiSelect ? [...val] : { ...((val && (val[0] || val)) || {}) }; // 多选值为Array 单选 object
    !_.eq(this.showValue, val) && (this.showValue = this.columnValue); // 外部赋值 同步改变select值
  }

  // 单选值变更  需要触发选项控制  设置配置的隐藏显示 和 必填
  initTheFieldControl(val) {
    if (!val || this.isMultiSelect) { // 多选 不支持
      return;
    }
    const _this = this;
    this.dataArea?.forEach((data) => {
      if (typeof data.fieldControl === "string") {
        data.fieldControl = JSON.parse(data.fieldControl);
      }
      data.fieldControl?.forEach((fc) => {
        const target = _this.findFieldColumn(fc.field);
        // console.log(data, target);
        if (Number(fc.flag) === 0) { // 显示 受控
          if (target) {
            target.showOptionField = _this.checkShowFieldWithValue(target.field, val[_this.defaultProps.value], "flag");
          }
        }
        if (Number(fc.requrein) === 0) { // 必填 受控
          if (target) {
            target.requireOptionField = _this.checkShowFieldWithValue(target.field, val[_this.defaultProps.value], "requrein");
          }
        }
      });
    });
    // zjh 显示隐藏 触发页签的显示隐藏
    this.businessData?.rootNode?.field45change();
  }

  findFieldColumn(field) {
    let res = this.mainAreaFields?.find((fd) => fd.field === field);
    if (!res) {
      const mxs = this.mainAreaFields?.filter((fd) => fd.fieldType === 11);
      for (let i = 0; i < mxs?.length; i++) {
        res = mxs[i].sub?.filter((mxfd) => mxfd.field === field)[0];
        if (res) {
          break;
        }
      }
      if (!res) {
        let zjAry = [];
        mxs?.forEach((mx) => {
          const zjs = mx.sub?.filter((mxfd) => mxfd.fieldType === 35);
          zjAry = [...zjAry, ...zjs];
        });
        for (let i = 0; i < zjAry.length; i++) {
          res = zjAry[i].sub?.filter((zjfd) => zjfd.field === field)[0];
          if (res) {
            break;
          }
        }
      }
    }

    return res;
  }

  // 检查 字段 在某个单选值下面是否可见|必填
  checkShowFieldWithValue(field, value, attr) {
    const { dataArea } = this;
    const ids = [];
    for (let i = 0; i < dataArea.length; i++) {
      const fd = dataArea[i].fieldControl?.find((itm) => itm.field === field);
      if (fd && fd[attr].toString() === "0") {
        ids.push(dataArea[i].id);
      }
    }
    return ids.includes(value) || ids.includes(`dept-${value}`);
  }

  // 设置 选项卡 对应字段的隐藏显示
  // setTabFieldShow() {
  //   const { uuid, rootNode } = this;
  //   const { id } = this.showValue;
  //   const { fieldAttr } = rootNode.originData;
  //   const f34 = fieldAttr.filter((fd) => fd.fieldType === 34)[0];
  //   if (id && f34 && f34.parentOption === uuid) {
  //     // 当前单选是某个选项卡的选择字段，其值可能控制tab页字段的隐藏显示
  //     this.mainAreaFields.forEach((item) => {
  //       if (item.isTabAttrField === 0 && item.isTabField === 1 && item.tabField === f34.field && item.tabId === id) {

  //       }
  //     });
  //   }
  // }
  // 触发自动赋值
  async setAutoAssignment() {
    let result = null;
    const { parentType } = this;
    console.log(this)
    if (this.showValue && Object.keys(this.showValue).length) {
      result =this.autoAssignValue || await selectService.queryBaseInfo({
        objectId: this.showValue?.id,
        objTypeId: this.areaObjTypeId
      });
      
      this.autoEvaluation?.forEach((element) => {
        let targetColumn;
        targetColumn = this.findColumnByField(element.destField);
        const targetParentType = targetColumn.parentType;

        if (parentType === targetParentType) {
          if (targetParentType === 11) {
            targetColumn = this.findDetailSameAreaField(element.destField);
          } else if (targetParentType === 35) {
            targetColumn = this.findSubsetSameAreaField(element.destField);
          }
        }
        this.getBaseInfo(targetColumn, element, result);
      });
      if (this.updateCashObject) {
        this.updateCashObject(this, result);
      }
    }
  }

  // 自动赋值 目标字段赋值
  getBaseInfo(column, config, result) {
    // 自动赋值--需要处理数据 有可能是（object-->string）
    const { fieldType } = column;
    const srcFieldType = Number(config.srcFieldType);
    const value = result ? result[config.srcField] : "";
    let newValue = "";
    if (fieldType === 1 || fieldType === 2) { // 目标字段为文本类型控件
      if (getObjectType(value) === "object") {
        if (srcFieldType === 29 || value.provinceId) { // 来源地址控件
          newValue = `${value.provinceName || ""} ${value.cityName || ""} ${value.districtName || ""} ${value.address || ""}`;
        } else {
          newValue = value.name || value.text || "";
        }
      } else if (getObjectType(value) === "array") {
        const txt = [];
        value.forEach((itm) => {
          txt.push(itm.name || itm);
        });
        newValue = txt.join();
      } else {
        newValue = value;
      }
    } else {
      newValue = value;
    }

    // 当自动赋值目标字段为明细或子集字段且 触发字段非同一级字段,则需要给每一行数据的 目标字段都赋值
    if (this.parentType !== column.parentType && (column.parentType === 19 ||column.parentType === 11 || column.parentType === 35)) {
      const parentNode = column.parentNode;
      parentNode.subDetails?.forEach((item) => {
        const subColumn = item.fields?.filter((fd) => fd.field === column.field)[0];
        if (subColumn) {
          subColumn.updateValueWithOptions(newValue, AssignmentOptions.automaticAssignment);
        }
      });
      return;
    }

    column.updateValueWithOptions(newValue, AssignmentOptions.automaticAssignment);
  }
}
