import BaseService from "../BaseService";

class Interface extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  async queryInterfaceconfig(params) {
    const { http } = this;
    return http.post("queryInterfaceConfig", params);
  }

  // /**
  //  * 帮助中心-更新日志接口
  //  */
  // querySysNoticeList(params) {
  //   const { http } = this;
  //   return http.post("querySysNoticeList", params);
  // }

  // /**
  //  * 帮助文档
  //  */
  // queryHelpDoc(params) {
  //   const { http } = this;
  //   return http.post("queryHelpDoc", params);
  // }

  // /**
  //  * 帮助文档内容
  //  */
  // queryHelpDocInfo(params) {
  //   const { http } = this;
  //   return http.post("queryHelpDocInfo", params);
  // }

  // /**
  //  * 帮助文档，常用问题搜索
  //  */
  // queryHelpDocByContent(params) {
  //   const { http } = this;
  //   return http.post("queryHelpDocByContent", params);
  // }
}

export default Interface;
