var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "time-axis-container",
      class: { "no-range": _vm.column.choiceRule === 1 },
    },
    [
      _c("en-date-picker", {
        staticStyle: { "min-width": "130px" },
        style: _vm.bindStyle,
        attrs: {
          "class-": "time-picker-select",
          type: _vm.column.choiceRule === 1 ? "date" : "datetimerange",
          format:
            _vm.column.choiceRule === 1 ? "yyyy-MM-dd" : "yyyy-MM-dd HH:mm",
          "value-format":
            _vm.column.choiceRule === 1 ? "yyyy-MM-dd" : "yyyy-MM-dd HH:mm",
          disabled: !_vm.column.editable,
          "picker-options": _vm.pickerOptions,
          "range-separator": "至",
        },
        on: { blur: _vm.handleDateChange },
        model: {
          value: _vm.column.dateValue,
          callback: function ($$v) {
            _vm.$set(_vm.column, "dateValue", $$v)
          },
          expression: "column.dateValue",
        },
      }),
      _vm.column.choiceRule === 1
        ? _c(
            "div",
            { staticClass: "slider-wrap" },
            [
              _c("div", { staticClass: "min-value" }, [
                _vm._v(_vm._s(_vm.timeRange[0])),
              ]),
              _c("el-slider", {
                ref: "slider",
                attrs: {
                  min: _vm.valueRange[0],
                  max: _vm.valueRange[1],
                  marks: _vm.marks,
                  range: "",
                  step: _vm.step,
                  "format-tooltip": _vm.formatToolTip,
                  disabled: !_vm.column.editable,
                },
                on: { change: _vm.handleSliderChange },
                model: {
                  value: _vm.column.showValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.column, "showValue", $$v)
                  },
                  expression: "column.showValue",
                },
              }),
              _c("div", { staticClass: "max-value" }, [
                _vm._v(_vm._s(_vm.timeRange[1])),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }