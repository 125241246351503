<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<!--
 * @Description: 业务建模详情组件,可能用到弹窗上
 * @Author: 梁平贤
 * @Date: 2020-06-16 11:10:39
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-29 13:23:19
-->
<template>
  <div class="business-detail"
       :class="{'business-detail__full-screen': isFullScreen }"
  >
    <div class="detail-header"
         :class="{
         'detail-header__full-screen': isFullScreen}">
      <BusinessHeader :business-data="businessData"
                      :businessParams="businessParams"></BusinessHeader>
    </div>
    <div class="detail-content"
         v-en-loading="enLoading"
         :style="themeInfo.openIndexTab?{height:`calc(100% - ${contentHeight}px)`}:{height:`calc(100% - ${contentHeight+45}px)`}"
         :class="{
         'detail-content__full-screen': isFullScreen}"
    >
        <div v-if="MultiTabColumn" :class="strokeStyle === '2' ? 'stroke-icon1' : 'stroke-icon2'"   @click.stop="openStroke">
          <en-icon
              name="iconshouqizhankaijiantou"
              size="12"
              color="#4694df"
              :class="{'transform':!showStroke}"
              style="cursor: pointer;"
            ></en-icon>
        </div>
      <div class="detail-inner"
           v-if="businessData"
           :class="{
           'detail-inner__full-screen':isFullScreen}">
        <el-form :model="businessData" ref="form" class="business-form" label-position="left" label-width="auto">
          <div
            class="section"
            :class="{'section__full-screen': isFullScreen}"
            v-for="(section, sectionIndex) in businessData.showSections"
            :key="section.uuid"
          >
            <div class="section-name" v-if="section.name && section.name.length > 0">
              <span class="color-block"></span>
              <span class="section-name-content">{{section.name}}</span>
            </div>

            <div class="row" v-for="(data, index) in section.areas" :key="index" v-show="judgeRowDisplay(data)">
              <div class="wrapper" :class="{'wrapper-mask-to-bounds':judgeRowMaskToBounds(data)}">
                <!--check-prop是指的这个字段的全路径,校验需要-->
                <business-column-rendering
                  v-if="MultiTabColumn ? column.fieldType !== 45 : true"
                  v-for="(column, colIndex) in data"
                  :key="column.uuid"
                  :column="column"
                  :businessData="businessData"
                  :isTab="false"
                  :validatorData="validatorData"
                  :check-prop="'showSections.'+sectionIndex+'.areas.'+index +'.'+ colIndex"
                >

                </business-column-rendering>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 页签 侧划 侧浮 -->
      <div :class="strokeStyle === '2' ? 'floating-window' : 'lateral-stroke'"  v-if="businessData && MultiTabColumn">
        <div class="detail-inner"
            :class="{
            'detail-inner__full-screen':isFullScreen}">
          <el-form :model="businessData" ref="form" class="business-form" label-position="left" label-width="auto">
            <div
              class="section"
              :class="{'section__full-screen': isFullScreen}"
              v-for="(section, sectionIndex) in businessData.showSections"
              :key="section.uuid"
            >
              <div  v-for="(data, index) in section.areas" :key="index" v-show="judgeRowDisplay(data)">
                  <!--check-prop是指的这个字段的全路径,校验需要-->
                  <business-column-rendering
                    class="wrapper" :class="{'wrapper-mask-to-bounds':judgeRowMaskToBounds(data)}"
                    v-if="column.fieldType === 45"
                    v-for="(column, colIndex) in data"
                    :key="column.uuid"
                    :column="column"
                    :isTab="true"
                    :businessData="businessData"
                    :validatorData="validatorData"
                    :check-prop="'showSections.'+sectionIndex+'.areas.'+index +'.'+ colIndex"
                  >
                  </business-column-rendering>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="detail-bottom"
         v-if="shouldShowButtonView"
         :class="{
         'detail-bottom__full-screen': isFullScreen,
         'detail-bottom__normal':!isFullScreen
         }">
      <BusinessBottom ref="businessBottom" :businessData="businessData"></BusinessBottom>
    </div>
    <!-- 自动赋值 -->
    <!-- 先注释掉，没发现有地方用到这个 czm -->
    <!-- <pop-auto-assign ref="autoAssignMod" :attr="testAttr"></pop-auto-assign> -->
    <page-switch v-if="businessData" :business-data="businessData"></page-switch>
  </div>
</template>
<script>
import { request } from "en-js";
import { cloneDeep } from "lodash";
import { enEqual } from "@/tools/compare";
import { createNamespacedHelpers } from "vuex";
import BusinessModel from "./data/detailModel/BusinessModel.js";
import { BusinessFieldType, AssignmentOptions, BusinessDataPageType } from "./data/BusinessCommonHeader";
import BusinessColumnRendering from "./components/columnRendering";
import pageSwitch from "./components/pageSwitch";
// import PopAutoAssign from "./components/popAutoAssign";
import BusinessHeader from "./components/headerAndBottom/BusinessHeader";
import BusinessBottom from "./components/headerAndBottom/BusinessBottom";
import flowButtons from "./mixins/ContainFlowButtons";
import goBackAndSave from "./mixins/MxGobackAndSave";

const { mapState: mapStateTheme } = createNamespacedHelpers("main");
export default {
  name: "BusinessDetail",
  provide() {
    return { $businessDetail: this };
  },
  components: {
    BusinessColumnRendering,
    // PopAutoAssign,
    BusinessHeader,
    BusinessBottom,
    pageSwitch
  },
  props: {
    hideCollect: {
      type: Boolean,
      default: false
    },
    // 不是路由时,需要的参数传这里面,一旦有值,业务建模详情就不会取路由上的参数了.
    notRouteParam: {
      type: Object,
      default: null
    },
    // 类型
    dataType: {
      type: Number,
      default: 0,
      validator(val) {
        // 0: route请求参数
        // 1: 正常走弹窗参数,请求数据(businessParams)
        // 2: 外部传入业务建模数据 也是弹窗
        return [0, 1, 2].indexOf(val) !== -1;
      }
    },
    // 外部存在的业务建模数据,不需要请求接口,直接渲染 目前如果是外部的数据,按钮不会提交
    exsitBusinessData: Object,
    shouldChangeLayout: {
      type: Boolean,
      default: true
    }
  },
  mixins: [flowButtons, goBackAndSave],
  data() {
    return {
      autoAssignVisible: false,
      // testAttr: {
      //   areaObjType: "002",
      //   areaObjTypeId: "171211134003906183",
      //   assistSelect: [],
      //   autoChooseArea: "deptAndPost"
      // },
      // 业务建模整体数据
      businessData: null,
      // loading装填
      enLoading: false,
      // 是否全屏
      isFullScreen: false,
      // pageType:1默认不全屏,能选择全屏或收起 2默认全屏,能关闭 3默认全屏,不能关闭  来源于UI两套页面样式
      pageType: BusinessDataPageType.normal,
      // 后端校验
      validatorData: {},
      showStroke: false,
      strokeStyle: "2",
      MultiTabColumn: false // 页签不是平铺型
    };
  },
  computed: {
    ...mapStateTheme(["themeInfo"]),
    // 外部的业务建模参数,有可能从路由或者prop里面找.
    businessParams() {
      return this.notRouteParam ? this.notRouteParam : this.$route.query;
    },
    // 是否需要展示底部按钮区域,因为共享人也会在底部展示,一起判断
    shouldShowButtonView() {
      return this.businessData && !this.businessData.hideBusinessBottom && (this.flowButtons.length > 0 || this.businessData.mdInfo.shareUserName.length);
    },
    contentHeight() {
      // 头部加底部按钮,当底部按钮没有的时候,需要不加64 顶部按钮暂时不加+45
      let yOffset = 0;
      if (this.isFullScreen) {
        yOffset += 16;
      }
      // 加顶部按钮
      yOffset += 50;
      if (this.shouldShowButtonView) {
        yOffset += 64;// 底部
      }
      yOffset += 50;
      return yOffset;
    }
  },
  beforeCreate() {
    console.time("业务建模接口/数据处理/渲染总耗时");
  },
  methods: {
    // 浮窗
    openStroke() {
      // a1 浮窗样式 a2 侧划窗口样式 b1定位浮窗图标 b2定位侧划图标
        const a1 = document.querySelector(".floating-window");
        const a2 = document.querySelector(".lateral-stroke");
        const b1 = document.querySelector(".stroke-icon1");
        const b2 = document.querySelector(".stroke-icon2");
      if (this.showStroke) {
        if (this.strokeStyle === "2") {
          // 侧浮
          a1.style.width = "0px";
          b1.style.right = "5px";
        } else {
          a2.style.width = "0px";
          b2.style.right = "5px";
        }
        this.showStroke = !this.showStroke;
      } else {
        if (this.strokeStyle === "2") {
          // 侧浮
          a1.style.width = "600px";
          b1.style.right = "585px";
        } else {
          a2.style.width = "600px";
          b2.style.right = "400px";
        }
        this.showStroke = !this.showStroke;
      }
    },
    // 初始化
    init() {
      window.fieldEditInfo = undefined;
      this.pageType = this.businessParams.pageType;
      this.isFullScreen = enEqual(this.pageType, BusinessDataPageType.fullscreen)
      || enEqual(this.pageType, BusinessDataPageType.newPage) || enEqual(this.pageType, BusinessDataPageType.toggleFullscreen);
      this.queryDetail();
    },
    filterCollectBtn() {
      const mdBtnList = this.businessData.mdInfo.eventEntity?.mdBtnList;
      mdBtnList.splice(mdBtnList.findIndex((item) => item.btnKey === "collect"), 1);
    },
    @request(true, "enLoading")
    // 获取数据详情
    async queryDetail() {
      if (this.dataType === 2) {
        // 已经存在的业务建模
        this.businessData = cloneDeep(this.exsitBusinessData);
      } else {
        console.log(this.businessParams, "[[[[[]]]]]");
        this.businessData = await BusinessModel.requestDetailData(this.businessParams, {
            dataType: this.dataType
        });
        console.log(this.businessData.showSections, "xczxc");
        let count = 0;
        this.businessData?.showSections.forEach((item, index) => {
          item.areas.forEach((el, inde) => {
            el.forEach((element, ind) => {
              if (element.fieldType === 45 && element.showStyleCss.TabLayout) {
                console.log("element", element);
                // 页签 并且不是平铺型
                // eslint-disable-next-line no-const-assign
                // 侧浮
                if (element.showStyleCss.TabLayout === "2") {
                  this.strokeStyle = "2";
                  count += 1;
                } else if (element.showStyleCss.TabLayout === "3") {
                  this.strokeStyle = "3";
                  count += 1;
                }
              }
            });
          });
        });
        if (count > 0) {
          this.MultiTabColumn = true;
          this.showStroke = true;
        } else {
          this.MultiTabColumn = false;
        }
        }
        // 根据入口隐藏收藏按钮
        if (this.hideCollect) {
          this.filterCollectBtn();
        }
        this.$nextTick(() => {
          this.fillFieldsValue();
          console.timeEnd("业务建模接口/数据处理/渲染总耗时");
        });
    },
    async refreshDetail() {
       if (this.dataType === 2) {
        // 已经存在的业务建模
        this.businessData = cloneDeep(this.exsitBusinessData);
      } else {
        this.businessData = await BusinessModel.requestDetailData(this.businessParams, {
          dataType: this.dataType
        });
      }
    },
    fillFieldsValue() {
      // 数据赋值
      let { fillFields } = this.$route.query;
      if (fillFields) {
        fillFields = JSON.parse(fillFields);
        Object.keys(fillFields).forEach((key) => {
          this.businessData.sections.forEach((section) => {
            section.areas.forEach((area) => {
              area.forEach((item) => {
                const value = fillFields[key];
                if (item.uuid === key && value !== "") {
                  item.updateValueWithOptions(value.columnValue || value, AssignmentOptions.manual);
                }
              });
            });
          });
        });
      }
    },
    // 根据数组返回数组内部是否都是不展示的字段,如果是,则隐藏
    judgeRowDisplay(row) {
      return row.some((column) => column.displayable);
    },
    // 是否需要在区域内展示,有些组件需要超出父类范围展示的在这里特殊处理 目前只处理页签
    judgeRowMaskToBounds(row) {
      return !row.some((column) => column.fieldType === BusinessFieldType.multiTab);
    },
    // 根据errorColumn寻找选项卡组件,并自动切换到错误的选项卡
    autoChangeToErrorTabWithColumn(column) {
      if (column.tabField && column.tabField.length > 0) {
        const tabColumn = column.findMainAreaField(column.tabField);
        tabColumn.currentSelectTabId = column.tabUuid;
      }
    },
    // 触发校验 触发完重置为全部校验
    triggerValidate(type) {
      this.businessData.checkType = type;
      return new Promise((resolve) => {
        this.$refs.form.validate((valid, errorObject) => {
          // 重置校验规则
          this.businessData.checkType = 1;
          if (valid) {
            // 可以提交
            resolve(true);
          } else {
            // 判断下是不是选项卡, 要自动切换到错误选项卡
            const column = Object.values(errorObject)[0][0]?.errorColumn;
            this.autoChangeToErrorTabWithColumn(column);
            const message = Object.values(errorObject)[0][0]?.message;
            this.$message.warning(message);
            // 自动滚动到错误的位置
            setTimeout(() => {
              const isError = document.getElementsByClassName("is-error");
              // 因为自身可能会是displayNone的,所以取父节点
              const errorDom = isError[0].querySelector(".el-form-item__error").parentNode || isError[0].querySelector("input").parentNode;
              errorDom?.scrollIntoView({
                block: "center",
                behavior: "smooth",
                inline: "center"
              });
            }, 1);
            resolve(false);
          }
        });
      });
    }
    // openAutoAssgin() {
    //   this.$refs.autoAssignMod.visible = true;
    // }
  },
  watch: {
    // 切换全屏, 非全屏
    isFullScreen() {
      if (this.shouldChangeLayout) {
        this.$eventHub.$emit("CHANGE_LAYOUT", this.isFullScreen ? 2 : 0);
      }
    }
  },
  mounted() {
    // 有初始化的业务逻辑都写到init里面,因为重新添加,会走一次这个方法
    this.init();
    console.log(this.businessData, "this.businessDatathis.businessData");
  },
  beforeMount() {
    if (enEqual(this.businessParams.pageType, 2) || enEqual(this.businessParams.pageType, 3)) {
      // 全屏
      this.shouldChangeLayout && this.$eventHub.$emit("CHANGE_LAYOUT", 2);
    } else {
      this.shouldChangeLayout && this.$eventHub.$emit("CHANGE_LAYOUT", 0);
    }
  }
};
</script>
<style lang="scss" scoped>
/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
::-webkit-scrollbar {
    display: none;
}
  .business-detail {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    .detail-header {
      height: 60px;
      background-color: #fff;
      border-bottom: 1px solid #E8ECF2;
      border-radius: 6px 6px 0 0;
      &__full-screen {
        border-bottom: none;
      }
    }
    .detail-content {
      position: relative;
      display: flex;
      justify-content: flex-start;
      flex:1;
      overflow: auto;
      .stroke-icon1{ //侧浮
      width: 30px;
      height: 30px;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      border-radius: 50%;
      // border: 1px solid #E8ECF2;
      background: #FFFFFF;
      position: absolute;
      z-index: 999;
      top:5px;
      right:585px;
      .transform{
       transform: rotate(180deg);
      }
        }
      .stroke-icon2{//侧滑
      width: 30px;
      height: 30px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: rgba(226, 231, 237, 0.19) 0px 10px 20px, rgba(226, 231, 237, 0.23) 0px 6px 6px;
      // border: 1px solid #E8ECF2;
      background: #FFFFFF;
      position: absolute;
      z-index: 999;
      top:5px;
      right:400px;
      transition: width 1s;
      .transform{
       transform: rotate(180deg);
      }
        }
      .floating-window{
        border-left: 1px solid #E8ECF2;
        z-index: 99;
        overflow: scroll;
        position: absolute;
        width: 600px;
        height: 100%;
        right: 0px;
        top: 0px;
        box-shadow: 0px 3px 7px 0px rgba(166,166,166,0.35);
      }
      /deep/ .floating-window .business-form{
        margin-top: 0!important;
      }
      .lateral-stroke{
        border-left: 1px solid #E8ECF2;
        overflow: scroll;
        width: 600px;
        height: 100%;
        overflow-y: auto;
        transition: width 1s;
      }
      .detail-inner {
        height: 100%;
        width: 100%;
        overflow: scroll;
        background-color: transparent;
        &__full-screen {
          background-color: #ECF1F7;
          overflow-y: scroll;
        }
      }
      .el-form {
        margin-top: 20px;
        padding: 0;
        height: 100%;
        display: flex;
        flex-direction: column;

        .section {
          .section-name {
            height: 35px;
            line-height: 35px;
            .color-block {
              vertical-align: middle;
              width: 3px;
              height: 16px;
              margin-left: 20px;
              background: rgba(70, 148, 223, 1);
              display: inline-block;
            }
            .section-name-content {
              vertical-align: middle;
              margin-left: 8px;
              font-size: 13px;
              font-weight: bold;
              color: rgba(51, 51, 51, 1);
              display: inline-block;
            }
          }
          background-color: #fff;
          // padding-top: 10px;
          border-top: 1px solid #E8ECF2;
          &__full-screen {
            border-radius: 4px;
            margin-top: 8px;
            border-top: none;
          }
          .row {
            width: 100%;
            /*overflow-x: auto;*/
            padding: 5px 20px;
            .wrapper {
              padding: 0 5px 13px 5px;
              display: flex;
              flex-flow: row;
              .business-column-rendering {
                margin-right: 10px;
              }
              .business-column-rendering:last-child {
                margin-right: 0;
              }
            }
            /*区域内限制, 在里面滚动, 页签比较特殊, 伸出头来*/
            .wrapper-mask-to-bounds {
              overflow-x: auto;
              overflow-y: hidden;
            }
          }
          &:first-child {
            margin-top: 0;
            border-top: none;
          }
          &:last-child {
            flex: 1;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
      background-color: #fff;
      width: 100%;
      &__full-screen {
        // margin: 8px 8px 0 8px;
        // width: calc(100% - 8px);
        .el-form{
          margin-top: 0;
        }
      }
    }
    .detail-bottom {
      height: 64px;
      background-color: #fff;
      border-top: 1px solid #E8ECF2;
      border-radius: 0 0 6px 6px;
      &__full-screen {
        margin: 0 8px 8px 8px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    &__full-screen {
      background-color: #ECF1F7;
    }

    /deep/ .date-picker-edit .el-input__prefix{
      margin-left: 0;
    }
    /deep/ .detail-content .detail-inner .notifier-line-box>div{
      height:auto
    }
    /deep/ .el-form-item__content .notifierColumn {
      padding: 16px 16px 6px 16px !important;
    }
    /deep/ .el-form-item__content .notifierColumn .person-cent {
      padding: 4px !important;
    }
    /deep/ .notifier-box .notifierColumn .person-cent .person-item .logo .name {
      width:50px !important;

    }
    /deep/ .el-form .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:after{
      content: "*";
      color: #ff3e3e!important;
      margin-left: 4px;
    }
  }

</style>
