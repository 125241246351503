<!--
 * @Author: pengyu
 * @Date: 2020-07-16 16:30:21
 * @LastEditors: pengyu
 * @LastEditTime: 2021-08-09 11:36:20
 * @Description: 时间轴控件
-->
<template>
  <div class="time-axis-container" :class="{'no-range': column.choiceRule === 1}">
      <en-date-picker
    class-="time-picker-select"
    style="min-width:130px;"
      v-model="column.dateValue"
      :type="column.choiceRule === 1 ? 'date' : 'datetimerange'"
      :format="column.choiceRule === 1 ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm'"
      :value-format="column.choiceRule === 1 ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm'"
      :disabled="!column.editable"
      :style="bindStyle"
      @blur="handleDateChange"
      :picker-options="pickerOptions"
      range-separator="至"
    ></en-date-picker>
    <div class="slider-wrap" v-if="column.choiceRule === 1">
      <div class="min-value">{{ timeRange[0] }}</div>
      <el-slider
        ref="slider"
        v-model="column.showValue"
        :min="valueRange[0]"
        :max="valueRange[1]"
        :marks="marks"
        range
        :step="step"
        :format-tooltip="formatToolTip"
        :disabled="!column.editable"
        @change="handleSliderChange"
      ></el-slider>
      <div class="max-value">{{ timeRange[1] }}</div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { roundNumWithDeci } from "en-js";
import { Message } from "element-ui";
import { businessService } from "@/api/businessModel";
import { AssignmentOptions, BusinessDataOpenType } from "../../../data/BusinessCommonHeader.js";
import MxColumnData from "../MxColumnData.js";

export default {
  name: "BMTimeAxisColumn",
  mixins: [MxColumnData],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    timeRange() {
      const arr = this.column.availableTime.split(",");
      if (!arr[0]) {
        arr[0] = "00:00";
      }
      if (!arr[1]) {
        arr[1] = "23:59";
      }
      return arr;
    },
    valueRange() {
      let min;
      let max;
      const tr = this.timeRange;
      const dd = this.column.dateValue || dayjs().format("YYYY-MM-DD");
      if (tr[0]) {
        min = dayjs(`${dayjs(dd).format("YYYY-MM-DD")} ${tr[0]}`).valueOf();
      } else {
        min = dayjs(`${dayjs(dd).format("YYYY-MM-DD")} 00:00`).valueOf();
      }
      if (tr[1]) {
        max = dayjs(`${dayjs(dd).format("YYYY-MM-DD")} ${tr[1]}`).valueOf();
      } else {
        max = dayjs(`${dayjs(dd).format("YYYY-MM-DD")} 23:59`).valueOf();
      }
      return [min, max];
    },
    step() {
      return (Number(this.column.accuracySet) || 1) * 60000;
    },
    pickerOptions() {
      const availableTime = this.column.availableTime;
      const ava = availableTime.split(",");
      return {
        start: ava[0],
        end: ava[1]
      };
    }
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      markArr: [],
      marks: {}
    };
  },
  methods: {
    /**
     * @description: 初始化 slider 配置
     */
    init() {
      const { columnValue, availableTime, choiceRule } = this.column;
      let vals;
      if (columnValue) {
        vals = columnValue.split(",");
        this.column.showValue = [new Date(vals[0]).getTime(), new Date(vals[1]).getTime()];
      } else {
        const ava = availableTime.split(",");
        const curDate = dayjs().format("YYYY-MM-DD");
        vals = [`${curDate} ${ava[0]}:00`, `${curDate} ${ava[0]}:00`];
      }

      if (choiceRule === 0) {
        this.column.dateValue = columnValue ? vals : "";
      } else {
        this.column.dateValue = dayjs(vals[0]).format("YYYY-MM-DD");
      }
      this.handleDateChange();
    },
    /**
     * @description: 时间改变
     */
    handleDateChange() {
      // 在资源 和 魔钥模板中有特殊逻辑
      const { dateValue } = this.column;
      if (this.column.choiceRule === 0) {
        // 允许跨天
        const sd = `${dateValue[0]}:00`;
        const ed = `${dateValue[1]}:00`;
        this.column.updateValueWithOptions(`${sd},${ed}`, AssignmentOptions.manual);
        return;
      }
      const { openType } = this.businessData;
      let refField = this.column.findMainAreaField("refResource"); // 资源名称
      if (!refField) {
        refField = this.column.findMainAreaField("applyGoods"); // 物品申请
      }

      if (refField) {
        const ref = refField.paramsForSubmit ? refField.paramsForSubmit[refField.field] : "";
        if (!ref && openType === BusinessDataOpenType.add) {
          // 新增的时候资源名称（申请物品）还没赋值，就用路由传过来的值
          const { resourceId, resourceName } = this.businessData.businessParams;
          if (resourceId && resourceName) {
            const val = [{ id: resourceId, name: resourceName }];
            refField.updateValueWithOptions(val, AssignmentOptions.default);
          }
        }
        if (ref && ref[0] && ref[0].id) {
          this.queryResourceReport(refField);
        } else if (refField.field !== "applyGoods") {
          this.column.dateValue = "";
          Message(`请先选择${refField.name}`);
        }
      }
    },
    /**
     * @description: 滑块改变
     */
    handleSliderChange() {
      if (this.column.dateValue) {
        this.updateValue();
      }
    },
    /**
     * @description: 格式化slider 显示
     * @param {type}
     * @return:
     */
    formatToolTip(value) {
      return dayjs(new Date(value)).format("HH:mm");
    },
    /**
     * @description: 更新值
     */
    updateValue() {
      const { showValue, dateValue } = this.column;
      const sd = this.formatToolTip(showValue[0]);
      const ed = this.formatToolTip(showValue[1]);
      const val = `${dateValue} ${sd}:00,${dateValue} ${ed}:00`;
      this.column.updateValueWithOptions(val, AssignmentOptions.manual);
    },
    /**
     * @description: 查询资源占用情况（用于设置el-slider marks）
     * @param: refField 非魔钥模板为 资源名称字段 魔钥模板为 物品申请字段
     */
    async queryResourceReport(refField) {
      try {
        this.$showLoading();
        const _this = this;
        this.marks = {};
        this.markArr = [];
        const { mdInfo } = this.businessData;
        const pa = {
          templateId: mdInfo.templateId,
          queryDate: this.column.dateValue,
          id: refField.paramsForSubmit[refField.field][0].id,
          pageNo: 1,
          pageSize: 100
        };

        const rsp = await businessService.queryResourceReport(pa);
        this.$hideLoading();
        rsp.forEach((itm) => {
          itm.data.forEach((citm) => {
            this.setUseResource(citm);
          });
        });
        setTimeout(() => {
          _this.setMarkStyle();
        }, 100);
      } catch (error) {
        this.$hideLoading();
      }
    },
    /**
     * @description: 设置已经占用的资源 (设置 el-slider marks)
     */
    setUseResource(data) {
      const { billStatus } = data;
      if (billStatus && ["002", "003", "006", "008"].includes(billStatus)) {
        return;
      }

      const { time } = data;
      const ts = time.split(",");
      const st = dayjs(ts[0]).valueOf();
      const et = dayjs(ts[1]).valueOf();
      this.$set(this.marks, st, {});
      this.$set(this.marks, et, {});
      const ob = {
        st,
        et,
        stLeft: this.getMarkLeftValue(st),
        etLeft: this.getMarkLeftValue(et)
      };
      ob.w = roundNumWithDeci(ob.etLeft - ob.stLeft, 4);
      this.markArr.push(ob);
    },
    /**
     * @description: 获取mark点的style left值
     */
    getMarkLeftValue(value) {
      const base = Number(this.valueRange[1]) - Number(this.valueRange[0]);
      const cha = Number(value) - Number(this.valueRange[0]);
      return roundNumWithDeci((cha / base) * 100, 4);
    },
    /**
     * @description: 设置mark初始点的样式
     */
    setMarkStyle() {
      const _this = this;
      this.markArr.forEach((itm) => {
        const mk = document.querySelectorAll(".el-slider__marks-stop");
        console.log(mk);
        const target = [...mk].filter((ele) => ele.style.left === `${itm.stLeft}%`);
        if (target.length) {
          const div = document.createElement("div");
          div.className = "slider-tips";
          div.innerHTML = `<span>${_this.formatToolTip(itm.st)}-${_this.formatToolTip(itm.et)}</span>`;
          target[0].append(div);
          target[0].style.width = `${itm.w}%`;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.time-axis-container {
  display: flex;
  & /deep/ .el-range-input,
  .el-range-separator {
    color: inherit;
  }
  & /deep/ .el-input__inner {
    padding-left: 18px !important;
  }

   & /deep/  .el-input__prefix{
    display: none;
  }

  &.no-range{
    & /deep/ .el-date-editor{
       width: 90px !important;
      .el-input__inner{
        width: 100%;
      }
    }
  }

  .slider-wrap {
    display: flex;
    flex:1;
    align-items: center;
    .min-value,
    .max-value {
       width:40px;
    }
    & /deep/ .el-slider__runway{
      height: 12px;
    }
    & /deep/ .el-slider__bar{
      height: 12px;
    }
    & /deep/ .el-slider__button-wrapper{
      top: -12.5px
    }
    & /deep/ .el-slider {
      flex: 1;
      .el-slider__button {
        border: 1px solid #c0c4cc;
      }
    }
    & /deep/ .el-slider__marks-stop {
      border-radius: 0 !important;
      background: #a3c9ee;
      transform: translateX(0);
      height: 12px;
      .slider-tips {
        position: relative;
        display: none;
        span {
          background: rgba(0, 0, 0, 0.6);
          line-height: 20px;
          padding: 2px 4px;
          border-radius: 5px;
          color: #ffffff;
          position: absolute;
          bottom: 5px;
          // left: 0;
          &::before {
            content: "";
            border: 5px solid transparent;
            border-top-color: rgba(0, 0, 0, 0.6);
            position: absolute;
            bottom: -10px;
            left: 10px;
          }
        }
      }
      &:hover {
        .slider-tips {
          display: block;
        }
      }
    }
  }
}
</style>
