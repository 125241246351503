<!--
 * @Author: 张小兵
 * @Date: 2020-07-24 10:57:40
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-13 10:17:37
 * @Description: 帮助中心搜索列表页
 * @FilePath: \user\src\views\help\helpSearchList.vue
-->
<template>
  <div class="search-card">
    <div class="card-list" v-en-loading="isLoading">
      <div class="card" v-for="(item, index) in cardList" :key="index">
        <img class="row-img" src="@/assets/notimport.png" />
        <div class="row-cent">
          <div class="main-title">
            <div class="txt-key">{{ item.type === 1 ? "帮助文档:" : "常见问题:" }}</div>
            <div class="txt-value">{{ item.name }}</div>
          </div>
          <div class="sub-title" v-html="item.relContentStr"></div>
        </div>
      </div>
      <div class="nodata" v-if="(cardList === null || cardList.length === 0) && !isLoading">
        <en-result type="NoData" :subTitle="text()"></en-result>
      </div>
    </div>
    <div class="card-bottom">
      <div class="result-txts">搜索结果，找到{{ cardList.length }}个结果</div>
    </div>
  </div>
</template>

<script>
import { request } from "en-js";
import { helpService } from "@/api/help/index";

export default {
  props: {
    searchTxt: {
      // 搜索值
      type: String,
      default: ""
    },
    searchType: {
      // 搜索类型
      type: String,
      default: ""
    }
  },
  data() {
    return {
      cardList: [], // 搜索列表数据
      searchCount: 0, // 搜索记录数量
      isLoading: true // 是否正在加载数据
    };
  },
  watch: {
    searchTxt() {
      this.queryHelpDocByContent();
    }
  },
  mounted() {
    if (this.searchTxt === "") {
      this.$message("请输入关键词");
      return;
    }
    this.queryHelpDocByContent();
  },
  methods: {
    @request(true, "isLoading", {
      subTitle: "数据加载中"
    })
    async queryHelpDocByContent() {
      // 根据关键字搜索帮助文档及问题列表
      const usinfo = localStorage.getItem("USERINFO");
      const info = JSON.parse(usinfo);
      const rspData = await helpService.queryHelpDocByContent({
        type: this.searchType,
        content: this.searchTxt,
        tenantId: info.tenantId
      });
      this.cardList = rspData || [];
      this.cardList.forEach((item) => {
        item.relContentStr = item.relContentStr.replace(this.searchTxt, `<span style="color:#6d97c9">${this.searchTxt}</span>`);
      });
      this.searchCount = this.cardList.length;
      console.log("item.contentStr", this.cardList);
      this.isLoading = false;
    },
    text() {
      const str = `很抱歉，没有找到与"${this.searchTxt}"相关的说明`;
      return str;
    }
  }
};
</script>

<style lang="scss" scoped>
.search-card {
  height: 100%;
  background-color: white;
  .card-list {
    width: 100%;
    height: 80%;
    overflow-y: scroll;
    .card {
      min-height: 100px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f3f3f3;
      .row-img {
        width: 90px;
        margin-left: 20px;
        margin-right: 10px;
      }
      .main-title {
        display: flex;
        align-items: center;
      }
      .txt-key {
        color: #3e90fe;
        font-size: 12px;
        font-weight: bold;
      }
      .txt-value {
        color: #333;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
      }
      .sub-title {
        color: #636c78;
        font-size: 12px;
        margin-top: 10px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .row-cent {
        width: 100%;
        text-align: left;
      }
    }
  }
  .card-bottom {
    width: 100%;
    height: 50px;
    position: absolute;
    padding: 5px;
    bottom: 0;
    display: flex;
    align-items: center;
    border-top: 1px solid #f3f3f3;
    .result-txts {
      margin-left: 20px;
      font-size: 12px;
      flex: 1;
      text-align: left;
      color: #91a1b7;
    }
  }
}
</style>
