<!--
 * @Descripttion: 关联数据
 * @Author: 彭博
 * @Date: 2020-08-27 11:05:54
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-28 13:17:27
-->
<template>
  <!-- 类型 关联添加 关联显示 -->
  <!-- 来源类型 -->
  <!-- 数据范围 -->
  <!-- 数据取值 -->
  <!-- 显示内容 -->
  <!-- 折叠设置 -->
  <div class="bm-associated" >

    <el-collapse v-model="showContent"  v-if="hasCollapse" @change="changeContent">
      <el-collapse-item :name="column.uuid">
        <template slot="title">
          <div class="title">
            <div style="display: flex;align-items:center;">
              <!-- 占行控件样式 -->
              <en-icon  name="iconguanlianfangan" size="18px" style="margin-right:5px;color:#46D48A" ></en-icon>
              <div class="name">
                <span style="vertical-align:middle" :class="{ 'title-requred': column.requrein === 0 }">{{ column.name }}</span>
              </div>
                <div class="count">（{{pageConfig && pageConfig.pageModel.total || column.columnValue.length}}）</div>
                <div>
                <en-icon  name="iconzhankai" color="#A9B5C6" class="drop-down" :style="{transform: showContent.length === 0 ? 'rotate(270deg)':'rotate(0deg)'}" ></en-icon>
                </div>
              </div>
            <div class="btn-list">
              <div div class="detail-btn" v-for="(btn, index) in btnList"  :style="btn.style" :key="index" @click.stop="btn.fn">
                  <en-icon v-if="btn.name !== '新增'" :title="btn.name" :name="btn.icon" style="margin-top:1px;" size="14" > </en-icon>
                  <en-icon v-if="btn.name === '新增'" :title="btn.name" :name="btn.icon" style="margin-top:1px;" size="10"> </en-icon>
                <span style="margin-top:1px;">{{ btn.name }}</span>
              </div>
             </div>
          </div>
        </template>
        <base-table :business-data="businessData" :column="column" @addSuccess="addBusinessRow" ref="baseTable" @addBasicRow="addBasicRow"
          v-en-loading="loading" :tableList="tableList" @handleFilterSortChange="handleFilterSortChange" :pageConfig="pageConfig"></base-table>
      </el-collapse-item>
    </el-collapse>
    <div v-else>
      <div class="title">
        <div style="display: flex;">
          <div class="name">
            <span style="vertical-align:middle" :class="{ 'title-requred': column.requrein === 0 }">{{ column.name }}</span>
          </div>
          <div class="detail-btn" v-for="(btn, index) in btnList" :key="index" @click.stop="btn.fn">
            <en-icon :title="btn.name" :name="btn.icon" size="18px" :style="btn.style"></en-icon>
            <span style="margin-top:1px;">{{ btn.name }}</span>
          </div>
        </div>
        <div class="count">{{pageConfig && pageConfig.pageModel.total || column.columnValue.length}}条数据</div>
      </div>
      <base-table :business-data="businessData" :column="column" @addSuccess="addBusinessRow" ref="baseTable" @addBasicRow="addBasicRow"
        v-en-loading="loading" :tableList="tableList" @handleFilterSortChange="handleFilterSortChange" :pageConfig="pageConfig"></base-table>
    </div>
    <relevancy-add ref="linkDialog" :business-data="businessData" @linkCallback="linkCallback"
      :requestParams="requestParams" :column="column" :title="column.name"></relevancy-add>
  </div>
</template>

<script>
import { request } from "en-js";
import { detailListService } from "@/api/businessModel";
import MxColumnData from "../MxColumnData.js";
import baseTable from "./baseTable";
import relevancyAdd from "../components/relevancyAdd";
import { AssignmentOptions, BusinessRelationType } from "../../../data/BusinessCommonHeader";
import MixinRelation from "../components/relevancyAdd/MixinRelation";

const basicAndAttendTypes = [BusinessRelationType.basic, BusinessRelationType.attend];

export default {
  name: "BMAssociatedComponent",
  components: { baseTable, relevancyAdd },
  mixins: [MxColumnData, MixinRelation],
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    // 是否有折叠面板
    hasCollapse() {
      return Number(this.column.selectLine) === 0;
    },
    // 是否允许多行
    isMutiple() {
      return this.column.multipleFlag === 0;
    },
    // 权限
    businessPower() {
      return this.businessData.powerList.find((power) => power.field === this.column.field);
    },
    // 前端分页
    tableList() {
      return this.column.columnValue;
      // const { editable } = this.column;
      // if (editable) return this.column.columnValue;
      // const { pageNo, pageSize } = this.pageModel;
      // return this.column.columnValue.slice((pageNo - 1) * pageSize, pageNo * pageSize);
    }
  },
  data() {
    const {
      version,
      templateId,
      field,
      templateDataId
    } = this.column;
    return {
      time: "",
      mdData: "",
      loading: false,
      requestParams: {
        requestAddr: "mdQueryNewRelationDataList",
        params: {
          ids: this.column.filterIds, // 过滤的数据id，多个以逗号隔开
          templateId: templateDataId,
          controlType: 1, // 1字段控件 2模板扩展
          relationType: 4, // 关联类型  1表单 2基础数据3.管理对象，4业务建模
          commParams: {
            relationSet: {},
            controlField: field,
            controlVersion: version,
            controlTempId: templateId
          }
        }
      },
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: null,
        totalPages: null,
        commParams: {}
      },
      pageConfig: null,
      showContent: [],
      btnList: [],
      selectSetting: {
        name: "关联",
        style: "color:#FFFFFF;background-color:#45D489",
        icon: "iconguanlianshuju-mingxi",
        fn: this.linkAdd
      },
      exportSetting: {
        name: "导出",
        style: "color:#26C393;border: 1px solid #FFA32B;color:#FFA32B",
        icon: "icondaochu",
        fn: this.exportRelationData
      },
      addSetting: {
        name: "新增",
        style: "color:#FFFFFF;background: #3E90FE;",
        icon: "iconxinzeng1",
        fn: this.checkCanAdd
      }
    };
  },
  methods: {

    changeContent(e) {
      console.log("111222", e, this.showContent);
    },
    exportRelationData() {
      if (!this.pageModel.total && !this.column.columnValue?.length) {
        this.$message.warning("当前无数据");
        return;
      }
      // 关联显示
      if (this.column.showModel === 1) {
        this.exportRelationDataByView();
      } else {
        this.exportRelationDataByAdd({
          ids: this.tableList.map((item) => item.id).join(",")
        });
      }
    },
    linkAdd() {
      this.$refs.linkDialog.openDialog(true);
    },
    linkCallback(backData) {
      // 允许添加多行
      if (this.isMutiple) {
        // 不需要去重 请求是过滤
        const list = this.column.columnValue.concat(backData);
        this.column.updateValueWithOptions(list, AssignmentOptions.manual);
      } else {
        this.column.updateValueWithOptions(backData, AssignmentOptions.manual);
      }
      // this.requestParams.params.ids = this.column.filterIds; // 过滤字段
      // 自动赋值 关联字段赋值 本控件只赋值主表区
      this.column.columnValue.forEach((data) => {
        const { lineData } = this.column.formatMappingRule(data);
        Object.keys(lineData).forEach((key) => {
          const values = lineData[key];
          const column = this.column.findColumn(this.column.mainAreaFields, key, "field");
          column.updateValueWithOptions(values, AssignmentOptions.manual);
        });
      });
    },
    pageChange(page) {
      Object.assign(this.pageModel, page);
      this.pageModel.totalPages = Math.ceil(this.column.columnValue.length / this.pageModel.pageSize);
    },
    handleFilterSortChange({ filterData, sortData }) {
      this.filterFields = filterData;
      this.sortField = sortData;
      this.pageConfig.pageModel.pageNo = 1;
      this.businessData.businessType === 1 ? this.mdQueryRelationData() : basicAndAttendTypes.includes(Number(this.column.relationType)) ? this.queryNewRelationDataList() : this.mdQuerySubDataList();
    },
    async mdQuerySubDataList(page = {}) {
      const {
        field, templateId, relationData, version, relationCondition, templateDataId
      } = this.column;
      const baseTable = this.$refs.baseTable;
      if (baseTable) {
        this.$refs.baseTable.loading = true;
      }
      const mdData = JSON.parse(this.businessData.paramsForSubmit().mdData);
      const { originData } = this.businessData.mdData;
      const relationSet = relationCondition.reduce((acc, item) => {
        acc[item.targetField] = item?.tparentField && mdData?.[item.tparentField]?.[0] ? mdData[item.tparentField][0][item.targetField]
          : mdData[item.targetField] || originData[item.targetField];
        return acc;
      }, {});
      const params = {
        controlTempId: templateId,
        controlField: field,
        controlVersion: version,
        parentId: 0,
        schemeVersionId: "",
        templateId: relationData || templateDataId,
        ids: "",
        area: 1,
        commonSet: JSON.stringify({ relationSet }),
        sort: JSON.stringify({}),
        filterFields: this.filterFields || JSON.stringify([]),
        sortField: this.sortField || JSON.stringify({})
      };
      this.params = params;

      if (this.mdData && this.mdData === JSON.stringify(params)) {
        if (baseTable) {
        this.$refs.baseTable.loading = false;
      }
        return;
      }

      console.log(2342434, this.mdData, JSON.stringify(params));

this.mdData = JSON.stringify(params);

      const result = await detailListService.mdQuerySubDataList({
        // id field pageNo pageSize
        pageNo: this.pageConfig?.pageModel?.pageNo,
        pageSize: this.pageConfig?.pageModel?.pageSize,
        ...params,
        ...page
      });
      if (baseTable) {
        this.$refs.baseTable.loading = false;
      }
      Object.assign(this.pageConfig.pageModel, result);
      this.column.updateValueWithOptions(result.records || [], AssignmentOptions.manual);
    },
    async mdQueryRelationData(page = {}) {
      const { templateId, id } = this.$route.query;
      if (!id) return;
      const params = {
        templateId,
        id,
        field: this.column.field
      };
      const result = await detailListService.mdQueryRelationData({
        // id field pageNo pageSize
        pageNo: this.pageConfig?.pageModel?.pageNo,
        pageSize: this.pageConfig?.pageModel?.pageSize,
        ...params,
        ...page,
        filterFields: this.filterFields || JSON.stringify([]),
        sortField: this.sortField || JSON.stringify({})
      });
      if (baseTable) {
        this.$refs.baseTable.loading = false;
      }
      Object.assign(this.pageConfig.pageModel, result);
      this.column.updateValueWithOptions(result.records || [], AssignmentOptions.manual);
      this.column.triggerInputLimitWatch = Math.random();
    },
    @request(true, "loading")
    async queryNewRelationDataList(page = {}, shouldFill) {
     const {
        relationRange, relationType, relationData, nameVariable, version, templateId, objectTypeId
      } = this.column;
      const commParams = {
        relationSet: {},
        controlField: nameVariable,
        controlVersion: version,
        controlTempId: templateId || objectTypeId
      };
      const params = {
        relationRange,
        relationType,
        templateId: relationData,
        commParams: JSON.stringify(commParams)
      };
      const result = await detailListService.mdQueryNewRelationDataList({
        // id field pageNo pageSize
        pageNo: this.pageConfig?.pageModel?.pageNo,
        pageSize: this.pageConfig?.pageModel?.pageSize,
        ...params,
        ...page,
        filterFields: this.filterFields || JSON.stringify([]),
        sortField: this.sortField || JSON.stringify({})
      });
      if (baseTable) {
        this.$refs.baseTable.loading = false;
      }
      Object.assign(this.pageConfig.pageModel, result);
      this.column.updateValueWithOptions(result.records || [], AssignmentOptions.manual);
      this.column.triggerInputLimitWatch = Math.random();
      if (shouldFill) {
        this.fillRelationField();
      }
    },
    // 添加业务建模行
    addBusinessRow() {
      basicAndAttendTypes.includes(Number(this.column.relationType)) ? this.queryNewRelationDataList({}, true) : this.mdQuerySubDataList();
    },
    // 添加基础数据行
    async addBasicRow(ids) {
      const {
        relationRange, relationType, relationData, nameVariable, version, templateId, objectTypeId
      } = this.column;
      const commParams = {
        relationSet: {},
        controlField: nameVariable,
        controlVersion: version,
        controlTempId: templateId || objectTypeId
      };
      const params = {
        relationRange,
        relationType,
        templateId: relationData,
        commParams: JSON.stringify(commParams)
      };
      const res = await detailListService.mdQueryNewRelationDataList({
        ...params,
        ids,
        pageNo: 1,
        pageSize: 10,
        onlyFilterById: 0,
        screen: "4"
      });
      const records = this.isMutiple ? [...res.records, ...this.column.columnValue] : res.records;
      if (this.pageConfig) {
        this.pageConfig.pageModel.total += res.records.length;
      }
      this.column.updateValueWithOptions(records || [], AssignmentOptions.manual);
      this.column.triggerInputLimitWatch = Math.random();
      this.fillRelationField();
    },
    int() {
      const {
      allowAddFlag, foldSet, showModel, editable, relationType, codeCheckIn, displayable, columnValue, uuid
    } = this.column;
    // 是否默认展开
    if (foldSet === "2" || foldSet === "") {
      this.showContent.push(uuid);
    }
    // 新增按钮
    if ((editable && allowAddFlag === 0)) {
      this.btnList.push(this.addSetting);
    }
    // 是否可以编辑 可编辑状态查看关联添加是否为真
    // showModel 1=>关联显示  2=>关联添加
    if (editable && showModel === 2) {
      this.btnList.push(this.selectSetting);
      // 关联显示 查看详情时获取关联显示数据 !editable && showModel === 1
    } else if (showModel === 1) {
      // 编辑状态加入分页
      this.pageModel.total = columnValue.length;
      this.pageModel.totalPages = Math.ceil(columnValue.length / this.pageModel.pageSize);
      // relationType 2=>基础数据 4=>业务建模 5=>考勤
      const isQueryRelation = basicAndAttendTypes.includes(Number(relationType));
      const isBasicRelation = this.businessData.businessType === 1;
      this.pageConfig = {
        pageModel: this.pageModel,
        // changeMethod: this.pageChange
        changeMethod: isBasicRelation ? this.mdQueryRelationData : isQueryRelation ? this.queryNewRelationDataList : this.mdQuerySubDataList
      };
      isBasicRelation ? this.mdQueryRelationData() : isQueryRelation ? this.queryNewRelationDataList() : this.mdQuerySubDataList();
    }
    if (Number(codeCheckIn) === 0 && !editable) {
      this.btnList.push(this.exportSetting);
    }
    }
  },
  watch: {
    showContent() {
      this.$refs.baseTable.recalculate();
    },
    "column.filterIds": {
      handler(ids) {
        this.requestParams.params.ids = ids;
      }
    }

  },
  created() {
   this.int();
  },
  mounted() {
    const _thisint = this.int;

    if (!this.$route.query.id && (this.column.loadType === "2" || this.column.loadType === 2)) { //  新增
      this.time = setInterval(() => {
    _thisint();
    }, 1500);
        }
  },
  beforeDestroy() {
    if (this.time) {
      clearTimeout(this.time);
    }
  }
};

</script>

<style scope lang="scss">
/deep/ .el-collapse {
    // border-top: none;
    border-bottom: none;
  }
.bm-associated {
  width: 100%;
  margin: 0;
  border-color: transparent;
  .title-requred {
    position: relative;
    &::before {
      content: "*";
      display: block;
      color: #ff3e3e;
      position: absolute;
      height: 100%;
      line-height: 100%;
      top: 0;
      right: -10%;
    }
  }

  /deep/ .el-collapse-item__content{
    padding-bottom: 0;
  }

  /deep/ .el-collapse-item__header{
    color: #333333;
    &.focusing:focus:not(:hover) {
      color:#333333;
    }
  }

  .el-collapse-item__header .el-icon-arrow-right{
      display: none !important;
  }

  // /deep/ .el-collapse {
  //   border-top: none;
  //   border-bottom: none;
  // }
  // /deep/ .el-collapse-item__header {
  //   border-radius: 4px 4px 0 0;
  //   background-color: transparent;
  //   border: none;
  // }
  // /deep/ .el-collapse-item__wrap {
  //   border-bottom: none;
  // }
  .title {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .count {
      font-size: 14px;
      font-weight: bold;
      color:#1A1C1E;
      margin-right: 6px;
    }
    .drop-down{
      width:12px;
      height:12px;
    }
  }
  .btn-list{
    display: flex;
    .detail-btn {
    width: 80px;
    height: 32px;
    border-radius: 4px;
    margin: 0 5px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #636c78;
    cursor: pointer;
    .en-icon {
      margin-right: 6px;
    }
  }
  }
  .name {
    background-color: transparent;
    font-weight: bold;
    color:#1A1C1E;
    font-size:14px;
    height: 48px;
    line-height: 48px;
  }
  .vxe-table-box{
    .vxe-cell{
      .parent{
        text-align: left;
      }
    }
  }
}
</style>
